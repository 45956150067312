import axios from 'axios';

// All APIS COnnected To Forms
export const getForms = async (e) => {
  const query = await axios.post('FrmForm/GetAll', e.queryKey[1]);
  const data = query.data.data;
  const isDone = query.data.isDone;
  const paginationResult = query.data.paginationResult;
  return { data, isDone, paginationResult };
};
// General API
// get Languages
export const getLanguages = async () => {
  const query = await axios.post('/Language/GetAll');
  const data = query.data.data;
  const isDone = query.data.isDone;
  return { data, isDone };
};
// get Form
export const getForm = (id, languageId) => {
  return async () => {
    const query = await axios.get(`/FrmForm/Get?id=${id}&langId=${languageId}`);
    const data = query.data.data;
    const isDone = query.data.isDone;
    return { data, isDone };
  };
};
// Add Form
export const addForm = async (e) => {
  const { data } = await axios.post('/FrmForm/Add', e);
  return data;
};
// Edit Form
export const editForm = async (e) => {
  const { data } = await axios.post('/FrmForm/Edit', e);
  return data;
};
// Delete Form
export const deleteForm = async (e) => {
  console.log('e', e);
  const { data } = await axios.post('/FrmForm/Delete', e);
  return data;
};
export const toggleStatusForm = async (e) => {
  const { data } = await axios.post('/FrmForm/ChangeStatus', e);
  return data;
};

// get FormQuestions
export const getFormQuestions = async (e) => {
  const query = await axios.post('/FrmQuestion/GetAllBy', e.queryKey[1]);
  const data = query.data.data;
  const isDone = query.data.isDone;
  const paginationResult = query.data.paginationResult;
  return { data, isDone, paginationResult };
};
// Add addFormQuestion
export const addFormQuestion = async (e) => {
  const { data } = await axios.post('/FrmQuestion/Add', e);
  return data;
};
// Edit FormQuestion
export const editFormQuestion = async (e) => {
  const { data } = await axios.post('/FrmQuestion/Edit', e);
  return data;
};

// Delete FormQuestion
export const deleteFormQuestion = async (e) => {
  const { data } = await axios.post('/FrmQuestion/Delete', e);
  return data;
};
// get Form
export const getQuestionTypes = async (e) => {
  const initial = {
    pageNumber: 0,
    pageSize: 0,
    numberOfPages: 0,
    isPaging: false,
    langId: 0,
  };
  const query = await axios.post('/FrmQuestionType/GetAll', initial);
  const data = query.data.data;
  const isDone = query.data.isDone;
  const paginationResult = query.data.paginationResult;
  console.log(paginationResult);
  return { data, isDone, paginationResult };
};

// get FormQuestionOptionType
export const getFormQuestionOptionType = async (e) => {
  const query = await axios.post('/FrmQoptionType/GetAll', e.queryKey[1]);
  const data = query.data.data;
  const isDone = query.data.isDone;
  const paginationResult = query.data.paginationResult;
  return { data, isDone, paginationResult };
};
// Add FormQuestionOptionsType
export const addFormQuestionOptionsType = async (e) => {
  const { data } = await axios.post('/FrmQoptionType/Add', e);
  return data;
};
// Add FormQuestionOptionsType
export const editFormQuestionOptionsType = async (e) => {
  const { data } = await axios.post('/FrmQoptionType/Edit', e);
  return data;
};
// Delete FormQuestionOptionsType
export const deleteFormQuestionOptionsType = async (e) => {
  const { data } = await axios.post('/FrmQoptionType/Delete', e);
  return data;
};

///////////////////////////////////////////////////////////////////
// get FormQuestionOption
export const getFormQuestionOption = async (e) => {
  const query = await axios.post('/FrmQoption/GetAllByTypeId', e.queryKey[1]);
  const data = query.data.data;
  const isDone = query.data.isDone;
  const paginationResult = query.data.paginationResult;
  return { data, isDone, paginationResult };
};
// Add FormQuestionOptions
export const addFormQuestionOptions = async (e) => {
  const { data } = await axios.post('/FrmQoption/Add', e);
  return data;
};
// Add FormQuestionOptions
export const editFormQuestionOptions = async (e) => {
  const { data } = await axios.post('/FrmQoption/Edit', e);
  return data;
};
// Delete FormQuestionOptions
export const deleteFormQuestionOptions = async (e) => {
  const { data } = await axios.post('/FrmQoption/Delete', e);
  return data;
};
