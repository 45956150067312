// eslint-disable-next-line import/no-anonymous-default-export
export default {
  'Create Page': 'إنشاء صفحة',
  Content: 'المحتوى',
  Location: 'الموقع',
  'Page Title': 'عنوان الصفحة',
  'SEO Title': 'عنوان السيو',
  'SEO Keywords': 'كلمات السيو الدالة ',
  Place: 'الموقع',
  Organizer: 'منظم',
  'Location Google Maps Url': 'الموقع على خريطة جوجل',
  'Page Options': 'خيارات الصفحة',
  uniqueUrl: 'عنوان مميز',
  'Parent Page': 'ربط بالصفحه',
  Projects: 'مشاريع',
  Date: 'تاريخ',
  'Show Sidebar': 'إظهار الشريط الجانبي',
  'Create New Page': 'إنشاء صفحة جديدة',
  'Replace Video': 'استبدال الفيديو',
  'Choose Video': 'اختيار الفيديو',
  From: 'من',
  To: 'لي',
  'Page History': 'سجل الصفحة',
  'Created by': 'انشأ من قبل',
  'Modified by': 'تم التعديل من قبل',
  'Save Sections Order': 'حفظ ترتيب الاقسام',
  'Sections Orders Changed Successfully': 'تم حفظ ترتيب الاقسام بنجاح',
  'Network Error': 'خطأ في الشبكة',
  'Untitled Section': 'قسم بلا عنوان',
  Card: 'كارت',
  tabs: 'تابات',
  Tabs: 'تابات',
  collapse: 'كولابس',
  Collapse: 'كولابس',
  Slider: 'صور متحركه',
  Statistics: 'إحصائيات',
  List: 'قائمة',
  'Add Section Title': 'إضافة عنوان للقسم',
  'Add Section': 'إضافة قسم',
  'Section Updated': 'تم تحديث القسم',
  'Section Settings': 'إعدادات القسم',
  'Show as a carousel': 'عرض كصور متحركه',
  Arrows: 'اسهم',
  Dots: 'نقاط',
  ArrowsAndDots: 'سهام ونقاط',
  'Save Settings': 'حفظ التغييرات',
  Top: 'اعلى',
  Bottom: 'أسفل',
  Left: 'شمال',
  Right: 'يمين',
  'Add Title': 'أضف عنوان',
  'Link Url': 'الرابط',
  'Add Description': 'اضف وصف',
  'External Link': 'رابط خارجي',
  'Update Item': 'تحديث العنصر',
  'Delete Card Item': 'حذف عنصر من الكارت',
  'Are you sure you want to delete this Card Item? All the data will be permanently removed from our servers forever. This action cannot be undone':
    'هل أنت متأكد أنك تريد حذف عنصر من الكارت هذا؟سيتم إزالتها بشكل دائم من خوادمنا إلى الأبد.لا يمكن التراجع عن هذا الإجراء',
  'Card Item, Deleted': 'تم مسح العنصر',
  'Sorry, This Card Item have Data Inside, Please Delete and Retry':
    'عذرا، هذه الكارت تحتوي على بيانات بداخلها يرجى حذف وإعادة المحاولة',
  'Add New Card Item': 'إضافة عنصر جديد',
  'Button Title': 'عنوان الزر',
  'Add Item': 'اضافة عنصر',
  'Save Section': 'حفظ القسم',
  'Tab Updated': 'التابات محدثه',
  'Section Style': 'نمط القسم',
  'Edit Section': 'تعديل القسم',
  'Section Status Changed Successfully': 'تم تعديل حاله القسم بنجاح',
  'Page Updated Successfully': 'تم تحديث الصفحة بنجاح',
  'Section, Deleted': 'تم حذف القسم',
  'Sorry, This Section have Data Inside, Please Delete and Retry':
    'عذرا، هذا القسم يحتوي على بيانات داخله، يرجى حذف وإعادة المحاولة',
  'Delete Section': 'حذف القسم',
  'Are you sure you want to delete this Section? All the data will be permanently removed from our servers forever. This action cannot be undone':
    'هل أنت متأكد أنك تريد حذف هذا القسم؟سيتم إزالتها بشكل دائم من خوادمنا إلى الأبد.لا يمكن التراجع عن هذا الإجراء',
  'Section Type': 'نوع القسم',
  Horizontal: 'أفقي',
  Vertical: 'عمودي',
  'Tab Item Updated': 'تم تحديث العنصر',
  'Add New Tab Item': 'إضافة عنصر جديد',
  'Collapse Item Updated': 'تم تحديث الكولابس',
  'Add New Collapse Item': 'اضافة عنصر لي الكولابس',
  'Add New Slider Item': 'إضافة عنصر جديد',
  Subtitle: 'العنوان الفرعي',
  'Slider Item, Deleted': 'تم حذف عنصر الصور المتحركه',
  'Sorry, This Slider Item have Data Inside, Please Delete and Retry':
    'عذرا، هذه الصور المتحركه تحتوي على بيانات بداخلها يرجى حذف وإعادة المحاولة',
  'Delete Slider Item': 'حذف عنصر في الصور المتحركه',
  'Are you sure you want to delete this Slider Item? All the data will be permanently removed from our servers forever. This action cannot be undone':
    'هل أنت متأكد أنك تريد حذف عنصر الصور المتحركه هذا ؟سيتم إزالتها بشكل دائم من خوادمنا إلى الأبد.لا يمكن التراجع عن هذا الإجراء',
  'Slider Item Updated': 'تم تحديث العنصر',
  'Save Lists': 'حفظ القائمة',
  'Are you sure you want to delete this page? All the data will be permanently removed from our servers forever. This action cannot be undone':
    'هل أنت متأكد أنك تريد حذف هذه الصفحة ؟سيتم إزالتها بشكل دائم من خوادمنا إلى الأبد.لا يمكن التراجع عن هذا الإجراء',
  'Delete Page': 'حذف الصفحة',
  'Add New List': 'إضافة قائمة جديدة',
  'Update List': 'تحديث القائمة',
  'Are you sure you want to delete this list item ? All the data will be permanently removed from our servers forever. This action cannot be undone':
    'هل أنت متأكد أنك تريد حذف العنصر من القائمة هذه؟سيتم إزالتها بشكل دائم من خوادمنا إلى الأبد.لا يمكن التراجع عن هذا الإجراء',
  'Delete list item': 'حذف العنصر من القائمة',
  'Are you sure you want to delete this list ? All the data will be permanently removed from our servers forever. This action cannot be undone':
    'هل أنت متأكد أنك تريد حذف هذه القائمة؟سيتم إزالتها بشكل دائم من خوادمنا إلى الأبد.لا يمكن التراجع عن هذا الإجراء',
  'Delete list': 'حذف القائمة',
  'Show Author': 'إظهار المؤلف',
  'Show Date': 'إظهار التاريخ',
  Category: 'فئة',
};
