import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import Notification from '../../../SharedComponents/Notification';
import { toggleStatusUser } from './ApiUser';

export default function ToggleUserStatus(props) {
  const { userStatus, id } = props;
  console.log('userStatus', userStatus);
  const [activeToggle, setActiveToggle] = useState(userStatus === 0 ? true : false);
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const toggleStatusUserMutation = useMutation(toggleStatusUser, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['users']);
    },
  });

  const handleClick = async (e) => {
    setActiveToggle(!activeToggle);
    const newData = { id: id, userStatusId: activeToggle === true ? 1 : 0 };

    try {
      await toggleStatusUserMutation.mutate(newData);
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <>
      {toggleStatusUserMutation.isSuccess && <Notification message={t('User Status, Updated')} />}
      <button onClick={handleClick} className="flex items-center cursor-pointer focus:outline-none">
        {/* <!-- toggle --> */}
        <div className="relative">
          {/* <!-- line --> */}
          <div
            className={` w-12 h-6 ${activeToggle ? 'bg-lightgreen' : 'bg-gray-400'}  rounded-full shadow-inner`}
          ></div>
          {/* <!-- dot --> */}
          <div
            className={`transition-all ease-in-out duration-700 absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 ${
              activeToggle ? 'right-0' : 'left-0'
            } `}
          ></div>
        </div>
        {/* <!-- label --> */}
      </button>
    </>
  );
}
