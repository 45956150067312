import axios from 'axios';

export const getLanguages = async () => {
  const query = await axios.post('/Language/GetAll');
  const data = query.data.data;
  const isDone = query.data.isDone;
  return { data, isDone };
};

export const getUsers = async (e) => {
  const query = await axios.post('/UserAccount/GetAllUsers', e.queryKey[1]);
  const data = query.data.data;
  const isDone = query.data.isDone;
  const paginationResult = query.data.paginationResult;
  return { data, isDone, paginationResult };
};

export const getUsersByName = async (name) => {
  const { data } = await axios.get(
    `/UserAccount/GetUserByRole?roleName=${name}`
  );
  return data;
};

export const getRoles = async () => {
  const { data } = await axios.get('/UserRole');
  return data;
};

export const editUser = async (e) => {
  const mutation = await axios.post('/UserAccount/EditUser', e);
  const data = mutation.data.data;
  const isDone = mutation.data.isDone;
  return { data, isDone };
};
export const assignRole = async (e) => {
  console.log('e', e);
  const mutation = await axios.post(`/UserAccount/AssignUserRoles`, e);
  const data = mutation.data.data;
  const isDone = mutation.data.isDone;
  const isMessage = mutation.data.message;
  const outId = mutation.data.outId;
  console.log(outId);
  return { data, isDone, isMessage, outId };
};
export const addUser = async (e) => {
  console.log('e', e);
  const mutation = await axios.post(`/UserAccount/AddUser`, e);
  const data = mutation.data.data;
  const isDone = mutation.data.isDone;
  const isMessage = mutation.data.message;
  const outId = mutation.data.outId;

  return { data, isDone, isMessage, outId };
};
export const toggleStatusUser = async (e) => {
  const mutation = await axios.post('/UserAccount/EditStatus', e);
  const data = mutation.data.data;
  const isDone = mutation.data.isDone;
  return { data, isDone };
};
export const deleteUser = async (id) => {
  const mutation = await axios.get(`/UserRole/Delete?id=${id}`);
  const data = mutation.data.data;
  const isDone = mutation.data.isDone;
  return { data, isDone };
};

// export const addPage = async (e) => {
//   console.log('e', e);
//   const { data } = await axios.post('/PgPage/Add', e[0]);
//   return data;
// };
