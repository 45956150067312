import { Field, FieldArray, Form, Formik } from 'formik';
import { useState } from 'react';
import Modal from '../../SharedComponents/Modal';
import PlusCircle from '../../SharedComponents/Svg/PlusCircle';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Loading from '../../SharedComponents/Loading';
import { addFaq, getLanguages } from './ApiFaq';
import { useTranslation } from 'react-i18next';
import LanguageList from '../../SharedComponents/LanguageList';

export default function AddFaq(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { id } = props.id ? props : 1;
  const { typeId } = props;
  const { t, i18n } = useTranslation();
  return (
    <>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`bg-darkblue space-x-1 ${
          i18n.language === 'en' ? '' : 'space-x-reverse'
        } text-sm font-bold text-white flex items-center hover:bg-darkbluehover shadow  px-4 py-2 rounded transition duration-300 ease-in-out`}
      >
        <PlusCircle className="w-5 h-5" /> <span>{t('Add Faq')}</span>
      </button>

      {isOpen && <ModalContainer id={id} typeId={typeId} isOpen={isOpen} setIsOpen={setIsOpen} />}
    </>
  );
}

const ModalContainer = (props) => {
  const { isOpen, setIsOpen, id, typeId } = props;
  const [languageId, setLanguageId] = useState(1);
  const [uploadSucess, setUploadSucess] = useState(false);
  const { t } = useTranslation();
  // Query Data To Fetch Languages
  const { data, isLoading, isError } = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });

  // create a loop for localisation
  const faqLngs = data?.data?.map((language) => ({
    languageId: language.id,
    question: '',
    answer: '',
  }));
  const initialValues = {
    faqLngs: faqLngs,
  };
  const queryClient = useQueryClient();
  // Mutate Data to Create New Page
  const mutation = useMutation(addFaq, {
    onSuccess: async (data) => {
      if (data.isDone) {
        queryClient.invalidateQueries(['faqs']);

        setIsOpen(false);
      }
    },
  });

  const onSubmit = (e) => {
    try {
      console.log(e);
      mutation.mutate(e);
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <>
      {data && data.data && (
        <Formik onSubmit={onSubmit} validateOnBlur={false} validateOnChange={false} initialValues={initialValues}>
          {({ values, setFieldValue, errors }) => {
            return (
              <Form>
                <Modal
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  onSubmit={onSubmit}
                  title={t('Create Faq')}
                  isLoading={mutation.isLoading}
                  add={true}
                >
                  {isLoading && <Loading />}
                  {isError && 'error'}

                  <LanguageList
                    className="flex transform translate-y-1 relative z-10 mb-4"
                    languages={data?.data}
                    languageId={languageId}
                    setLanguageId={setLanguageId}
                  >
                    <hr className="border w-full absolute bottom-0 left-0 z-0" />
                  </LanguageList>

                  {/* Submit Form */}

                  {values &&
                    values.faqLngs &&
                    values?.faqLngs.map((asset, index) => (
                      <FieldArray
                        key={index}
                        name="faqLngs"
                        render={(arrayHelpers) => (
                          <>
                            {languageId === asset.languageId && (
                              <>
                                <label>
                                  <b className="font-semibold block text-gray-700">{t('Question')}</b>
                                  <Field
                                    className="border rounded  w-full mb-4 p-1"
                                    type="text"
                                    name="title"
                                    name={`faqLngs[${index}].question`}
                                  />
                                </label>
                                <label>
                                  <b className="font-semibold block text-gray-700">{t('Answer')}</b>
                                  <Field
                                    className="border rounded  w-full mb-4 p-1"
                                    as="textarea"
                                    name="title"
                                    name={`faqLngs[${index}].answer`}
                                  />
                                </label>
                              </>
                            )}
                          </>
                        )}
                      />
                    ))}
                </Modal>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};
