import { Field, FieldArray, Form, Formik } from 'formik';
import { useState } from 'react';
import Modal from '../../../SharedComponents/Modal';
import PlusCircle from '../../../SharedComponents/Svg/PlusCircle';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Loading from '../../../SharedComponents/Loading';
import * as yup from 'yup';
import { getLanguages, addGalleryAlbumItem } from '../ApiGallery';
import { useParams } from 'react-router';
import { DateTime } from 'luxon';
import AddImage from './AddImage';
import AddYoutube from './AddYoutube';
import { useTranslation } from 'react-i18next';
import LanguageList from '../../../SharedComponents/LanguageList';

export default function AddGalleyImage(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { id } = props.id ? props : 1;
  const { typeId } = props;
  const { t, i18n } = useTranslation();
  return (
    <>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`bg-lightgreen space-x-1 ${
          i18n.language === 'en' ? '' : 'space-x-reverse'
        } text-sm font-bold text-white flex items-center hover:bg-lightgreenhover shadow  px-4 py-2 rounded transition duration-300 ease-in-out`}
      >
        <PlusCircle className="w-5 h-5" /> <span>{t('Create Gallery Item')}</span>
      </button>

      {isOpen && <ModalContainer id={id} typeId={typeId} isOpen={isOpen} setIsOpen={setIsOpen} />}
    </>
  );
}

const ModalContainer = (props) => {
  const { isOpen, setIsOpen } = props;
  const [languageId, setLanguageId] = useState(1);
  const { albumId } = useParams();
  const { t, i18n } = useTranslation();
  // Query Data To Fetch Languages
  const { data, isLoading, isError } = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });

  // create a loop for localisation
  const glAlbumItemLngs = data?.data?.map((language) => ({
    languageId: language.id,
    title: '',
    description: '',
    btnNextText: '',
    btnPreviousText: '',
    btnCloseText: '',
    keywords: '',
  }));
  const initialValues = {
    imageId: 137,
    albumId: albumId,
    orderNum: 1,
    btnNextCss: '',
    btnNextLink: '',
    btnPreviousCss: '',
    btnPreviousLink: '',
    btnCloseCss: '',
    btnCloseLink: '',
    date: DateTime.now().toISODate(),
    glAlbumItemLngs: glAlbumItemLngs,
  };
  const queryClient = useQueryClient();
  // Mutate Data to Create New Page
  const mutation = useMutation(addGalleryAlbumItem, {
    onSuccess: async (data) => {
      if (data.isDone) {
        queryClient.invalidateQueries(['galleryAlbums']);
        queryClient.invalidateQueries(['galleryAlbum']);

        setIsOpen(false);
      }
    },
  });

  const onSubmit = async (e) => {
    try {
      mutation.mutate(e);
    } catch (error) {
      console.log('error', error);
    }
  };

  const CreateGalleryAlbumValidationSchema = yup.object().shape({
    orderNum: yup.number().required().positive().integer(),
    imageId: yup.number().required('This Field is Required').positive().integer(),
  });
  return (
    <>
      {data && data.data && (
        <Formik
          onSubmit={onSubmit}
          validationSchema={CreateGalleryAlbumValidationSchema}
          validateOnBlur={true}
          validateOnChange={false}
          initialValues={initialValues}
        >
          {({ values, setFieldValue, errors }) => {
            return (
              <Form>
                <Modal
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  onSubmit={onSubmit}
                  title={t('Create Gallery Album')}
                  isLoading={mutation.isLoading}
                  add={true}
                >
                  {isLoading && <Loading />}
                  {isError && 'error'}
                  {data && (
                    <LanguageList
                      className="flex transform translate-y-1 relative z-10 mb-4"
                      languages={data?.data}
                      languageId={languageId}
                      setLanguageId={setLanguageId}
                    >
                      <hr className="border w-full absolute bottom-0 left-0 z-0" />
                    </LanguageList>
                  )}
                  {mutation.isError && (
                    <small className="text-sm bg-red-100 block p-2 text-red-500 mb-2">
                      {t('Order Number is Duplicated Kindly Change it')}
                    </small>
                  )}
                  {/* Submit Form */}
                  <div className="grid grid-cols-3 mb-2">
                    <label className={`flex items-center space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
                      <Field type="radio" name="isVideo" value="false" className="w-4 h-4" />
                      <span>{t('Image')}</span>
                    </label>
                    <label className={`flex items-center space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
                      <Field type="radio" name="isVideo" value="true" className="w-4 h-4" />
                      <span>{t('Video')}</span>
                    </label>
                  </div>
                  <label className=" mb-4 block hidden">
                    <b className="font-semibold block text-gray-700">{t('Image Id')}</b>
                    <Field
                      className={`border rounded  w-full p-1 ${errors.imageId ? 'border-red-500' : ''}`}
                      name={`imageId`}
                      type="number"
                    />
                    {errors.imageId && <small className="text-xs text-red-500">{errors.imageId}</small>}
                  </label>
                  {/* <span> {values.imageUrl}</span> */}
                  {values.isVideo === 'false' && (
                    <div className="h-64 focus:outline-none group rounded border-dashed border-2 mb-4 relative">
                      {(values.imageUrl && (
                        <>
                          <img
                            className="object-fit h-64 w-full"
                            alt="img"
                            src={`${process.env.REACT_APP_IMAGE_PATH}${values.imageUrl}`}
                          />
                          <div className="absolute group-hover:opacity-100 opacity-0 inset-0 flex items-center justify-center">
                            <AddImage edit={true} setField={setFieldValue} />
                          </div>
                        </>
                      )) || (
                        <div className="absolute inset-0 flex items-center justify-center">
                          <AddImage setField={setFieldValue} />
                        </div>
                      )}
                    </div>
                  )}
                  {values.isVideo === 'true' && (
                    <div className="h-64 focus:outline-none group rounded border-dashed border-2 mb-4 relative">
                      {(values.imageUrl && (
                        <>
                          <img
                            className="object-fit h-64 w-full"
                            alt="img"
                            src={`${process.env.REACT_APP_IMAGE_PATH}${values.imageUrl}`}
                          />
                          <div className="absolute group-hover:opacity-100 opacity-0 inset-0 flex items-center justify-center">
                            <AddYoutube edit={true} setField={setFieldValue} />
                          </div>
                        </>
                      )) || (
                        <div className="absolute inset-0 flex items-center justify-center">
                          <AddYoutube setField={setFieldValue} />
                        </div>
                      )}
                    </div>
                  )}

                  {values &&
                    values.glAlbumItemLngs &&
                    values?.glAlbumItemLngs.map((asset, index) => (
                      <FieldArray
                        key={index}
                        name="glAlbumItemLngs"
                        render={(arrayHelpers) => (
                          <>
                            {languageId === asset.languageId && (
                              <>
                                <label>
                                  <b
                                    className={`font-semibold block text-gray-700 ${
                                      i18n.language === 'en' ? '' : 'text-right'
                                    }`}
                                  >
                                    {t('Title')}
                                  </b>
                                  <Field
                                    className="border rounded  w-full mb-4 p-1"
                                    type="text"
                                    name="title"
                                    name={`glAlbumItemLngs[${index}].title`}
                                  />
                                </label>
                                <label>
                                  <b
                                    className={`font-semibold block text-gray-700 ${
                                      i18n.language === 'en' ? '' : 'text-right'
                                    }`}
                                  >
                                    {t('Description')}
                                  </b>
                                  <Field
                                    className={`border rounded  w-full mb-4 p-1`}
                                    name={`glAlbumItemLngs[${index}].description`}
                                    type="text"
                                  />
                                </label>
                                <label>
                                  <b
                                    className={`font-semibold block text-gray-700 ${
                                      i18n.language === 'en' ? '' : 'text-right'
                                    }`}
                                  >
                                    {t('Button Next Text')}
                                  </b>
                                  <Field
                                    className={`border rounded  w-full mb-4 p-1`}
                                    name={`glAlbumItemLngs[${index}].btnNextText`}
                                    type="text"
                                  />
                                </label>
                                <label>
                                  <b
                                    className={`font-semibold block text-gray-700 ${
                                      i18n.language === 'en' ? '' : 'text-right'
                                    }`}
                                  >
                                    {t('Button Previous Text')}
                                  </b>
                                  <Field
                                    className={`border rounded  w-full mb-4 p-1`}
                                    name={`glAlbumItemLngs[${index}].btnPreviousText`}
                                    type="text"
                                  />
                                </label>
                                <label>
                                  <b
                                    className={`font-semibold block text-gray-700 ${
                                      i18n.language === 'en' ? '' : 'text-right'
                                    }`}
                                  >
                                    {t('Button Close Text')}
                                  </b>
                                  <Field
                                    className={`border rounded  w-full mb-4 p-1`}
                                    name={`glAlbumItemLngs[${index}].btnCloseText`}
                                    type="text"
                                  />
                                </label>
                                <label>
                                  <b
                                    className={`font-semibold block text-gray-700 ${
                                      i18n.language === 'en' ? '' : 'text-right'
                                    }`}
                                  >
                                    {t('Keywords')}
                                  </b>
                                  <Field
                                    className={`border rounded  w-full mb-4 p-1`}
                                    name={`glAlbumItemLngs[${index}].keywords`}
                                    type="text"
                                  />
                                </label>
                              </>
                            )}
                          </>
                        )}
                      />
                    ))}

                  <label>
                    <b className={`font-semibold block text-gray-700 ${i18n.language === 'en' ? '' : 'text-right'}`}>
                      {t('Order Number')}
                    </b>
                    <Field className="border rounded  w-full mb-4 p-1" name={`orderNum`} type="number" />
                  </label>
                  <label>
                    <b className={`font-semibold block text-gray-700 ${i18n.language === 'en' ? '' : 'text-right'}`}>
                      {t('Button Next Link')}
                    </b>
                    <Field className={`border rounded  w-full mb-4 p-1`} name={`.btnNextLink`} type="text" />
                  </label>
                  <label>
                    <b className={`font-semibold block text-gray-700 ${i18n.language === 'en' ? '' : 'text-right'}`}>
                      {t('Button Previous Link')}
                    </b>
                    <Field className={`border rounded  w-full mb-4 p-1`} name={`.btnPreviousLink`} type="text" />
                  </label>
                  <label>
                    <b className={`font-semibold block text-gray-700 ${i18n.language === 'en' ? '' : 'text-right'}`}>
                      {t('Button Close Link')}
                    </b>
                    <Field className={`border rounded  w-full mb-4 p-1`} name={`.btnCloseLink`} type="text" />
                  </label>
                </Modal>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};
