import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import Notification from '../../../SharedComponents/Notification';
import Flag from '../../../SharedComponents/Svg/Flag';
import { saveSectionOrder } from './ApiSections';

export default function SaveSectionOrder(props) {
  const { state } = props;
  const queryClient = useQueryClient();
  const { t, i18n } = useTranslation();
  const { mutate, data } = useMutation(saveSectionOrder, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['sections']);
    },
  });
  const handleOrder = async (e) => {
    const newData = state.sections.map((e, index) => {
      return { orderNumber: index + 1, itemId: e.id };
    });
    try {
      console.log(e);
      await mutate(newData);
    } catch (error) {
      console.log('error', error);
    }
  };
  return (
    <>
      <button
        type="button"
        onClick={handleOrder}
        className={`bg-darkblue space-x-1 ${
          i18n.language === 'en' ? '' : 'space-x-reverse'
        } mb-4 text-sm font-bold text-white flex items-center hover:bg-darkbluehover shadow  px-4 py-2 rounded transition duration-300 ease-in-out`}
      >
        <Flag className="w-5 h-5" /> <span>{t('Save Sections Order')}</span>
      </button>
      {data && data.isDone === true && <Notification message={t('Sections Orders Changed Successfully')} />}
      {data && data.isDone === false && <Notification error={true} message={t('Network Error')} />}
    </>
  );
}
