import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import ChevronRight from '../../../../SharedComponents/Svg/ChevronRight';
import DeleteSection from '../DeleteSection';
import EditSection from '../EditSection';
import SectionHeader from '../SectionHeader';
import ToggleSectionStatus from '../ToggleSectionStatus';
import AddListParent from './AddListParent';
import { addListParent } from './ApiList';
import ListParent from './ListParent';

export default function List(props) {
  const { section, languages, provided } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [languageId, setLanguageId] = useState(1);
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  // Mutate Data to Create New List Parent
  const addListMutation = useMutation(addListParent, {
    onSuccess: async (data) => {
      if (data.isDone) {
        queryClient.resetQueries(['sections']);
        queryClient.resetQueries('sections');
      }
    },
  });

  return (
    <>
      <div className="block bg-white w-full shadow border mb-4">
        <SectionHeader
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          section={section}
          languageId={languageId}
          provided={provided}
        />{' '}
        <Formik initialValues={section}>
          {({ values, setFieldValue, setValues }) => {
            return (
              <>
                <div className={`bg-white px-4 overflow-hidden ${isOpen ? 'py-3 h-full  ' : '  py-0 h-0'}`}>
                  <div>
                    <div className="flex justify-between relative items-center">
                      <div className="flex transform -translate-y-1 relative z-10">
                        {t('Section Type')} : {t('List')}
                      </div>
                      <div>
                        <div>
                          {languages && languages.data && (
                            <div className="flex justify-end transform translate-y-1 relative z-10 mb-4">
                              {languages?.data?.data.map((language) => (
                                <div
                                  key={language.id}
                                  className={`flex justify-center items-center space-x-2 ${
                                    languageId === language.id ? 'text-pagetabhover border-pagetabhover' : ''
                                  }   hover:text-pagetabhover pb-4 px-4 z-50 border-b-2 hover:border-pagetabhover transition duration-300 ease-in-out cursor-pointer `}
                                  onClick={(e) => setLanguageId(language.id)}
                                >
                                  <span>{language.name}</span>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                      <hr className="border w-full absolute top-0 left-0 z-0 mt-11" />
                    </div>
                  </div>
                  <div>
                    <AddListParent pgSectionListId={values.pgSectionListId} addListMutation={addListMutation} />
                  </div>
                  {values &&
                    values.pgSectionList &&
                    values.pgSectionList.pgSectionListParents.map((item) => {
                      return (
                        <ListParent
                          key={item.id}
                          setFieldValue={setFieldValue}
                          languageId={languageId}
                          pgSectionListParents={item}
                        />
                      );
                    })}

                  <div className="flex justify-end py-4">
                    <button
                      type="button"
                      onClick={() => {}}
                      className="bg-lightgreen py-2 px-2 flex justify-center text-center h-full text-sm font-bold text-white items-center hover:bg-lightgreenhover text-centerhover shadow  rounded transition duration-300 ease-in-out"
                    >
                      {t('Save Lists')}
                    </button>
                  </div>
                </div>
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
}
