import { useTranslation } from 'react-i18next';
import { Field, Formik } from 'formik';
export default function TableSearch(props) {
  const { onChangeEntries, entries, onSearch } = props;

  const onKeyDownCapture = (e) => {
    console.log('e', e);
  };

  const { t, i18n } = useTranslation();
  return (
    <div
      className={`flex items-center ${
        i18n.language === 'en' ? '' : 'space-x-reverse'
      } space-x-8 transform -translate-y-3`}
    >
      <div className={`flex items-center space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'} text-gray-600`}>
        <span>{t('Show')} </span>
        <select className="border rounded w-20 py-2 px-1" onChange={onChangeEntries} defaultValue={entries}>
          <option value="10">10 </option>
          <option value="20">20 </option>
          <option value="30">30 </option>
        </select>
        <span>{t('entries')}</span>
      </div>

      <div className={`flex items-center ${i18n.language === 'en' ? '' : 'space-x-reverse'} space-x-4 text-gray-600`}>
        <Formik initialValues={{ target: { value: '' } }} onKeyDownCapture={onKeyDownCapture}>
          {({ values }) => {
            return (
              <>
                <Field
                  name="target.value"
                  onKeyDownCapture={(e) => {
                    console.log(e.key);
                    if (e.key === 'Enter') {
                      onSearch(e);
                    }
                  }}
                  className="border rounded w-64 py-2 px-1"
                  type="text"
                />
                <button
                  type="button"
                  onClick={(e) => onSearch(values)}
                  className="border rounded py-2 px-4 bg-lightgreen hover:bg-lightgreenhover text-white"
                >
                  {t('Search')}
                </button>
              </>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}
