import { Field, FieldArray, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import Modal from '../../../../SharedComponents/Modal';
import PlusCircle from '../../../../SharedComponents/Svg/PlusCircle';
import React from 'react';
import { useQuery, useQueryClient } from 'react-query';
import Loading from '../../../../SharedComponents/Loading';
import { getLanguages } from '../ApiSections';
import RichTextEditor from '../../Pages/RichTextEditor';
import AddTabImage from './AddTabImage';
import { useTranslation } from 'react-i18next';
import LanguageList from '../../../../SharedComponents/LanguageList';

export default function AddTabItem(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { id } = props.id ? props : 1;
  const { typeId, activeMenu, orderNumber, addTabItemMutation, tabCardId } = props;
  const { t, i18n } = useTranslation();
  return (
    <>
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className={`bg-lightgreen space-x-1 ${
          i18n.language === 'en' ? '' : 'space-x-reverse'
        } text-sm font-bold text-white flex items-center hover:bg-lightgreenhover shadow  px-4 py-2 rounded transition duration-300 ease-in-out`}
      >
        <PlusCircle className="w-5 h-5" /> <span>{t('Add New Tab Item')}</span>
      </button>

      {isOpen && (
        <ModalContainer
          id={id}
          typeId={typeId}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          activeMenu={activeMenu}
          orderNumber={orderNumber}
          addTabItemMutation={addTabItemMutation}
          tabCardId={tabCardId}
        />
      )}
    </>
  );
}

const ModalContainer = (props) => {
  const { isOpen, setIsOpen, id, typeId, activeMenu, orderNumber, addTabItemMutation, tabCardId } = props;
  const [languageId, setLanguageId] = useState(1);
  const [uploadSucess, setUploadSucess] = useState(false);
  const { t, i18n } = useTranslation();
  // Query Data To Fetch Languages
  const { data, isLoading, isError } = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });
  const [activePage, setActivePage] = useState();
  const queryClient = useQueryClient();
  // create a loop for localisation
  const pgTabItemLng = data?.data?.map((language) => ({
    languageId: language.id,
    title: '',
    text: '',
  }));
  const initialValues = {
    orderNum: orderNumber,
    pgTabId: tabCardId,
    pgTabItemLng: pgTabItemLng,
  };

  const onSubmit = async (e) => {
    try {
      console.log(e);
      await addTabItemMutation.mutate(e);
      setIsOpen(false);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {}, [activePage]);
  return (
    <>
      {data && data.data && (
        <Formik
          // onSubmit={onSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={initialValues}
        >
          {({ values, setFieldValue, errors }) => {
            return (
              <Form>
                <Modal
                  className="sm:max-w-3xl"
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  onSubmit={() => onSubmit(values)}
                  title={t('Add Item')}
                  isLoading={addTabItemMutation.isLoading}
                  add={true}
                  submitType="button"
                >
                  {isLoading && <Loading />}
                  {isError && 'error'}
                  {data && (
                    <LanguageList
                      className="flex transform translate-y-1 relative z-10 mb-4"
                      languages={data?.data}
                      languageId={languageId}
                      setLanguageId={setLanguageId}
                    >
                      <hr className="border w-full absolute bottom-0 left-0 z-0" />
                    </LanguageList>
                  )}

                  {/* Submit Form */}
                  <div className="grid grid-cols-1 gap-6">
                    <div className="h-52 focus:outline-none group rounded border-dashed border-2 mb-2 relative">
                      {(values.img && (
                        <>
                          <div className="flex items-center justify-center h-full p-2">
                            <img
                              className="w-full h-full object-contain"
                              alt="img"
                              src={`${process.env.REACT_APP_IMAGE_PATH}${values.img}`}
                            />{' '}
                          </div>
                          <div className="absolute group-hover:opacity-100 opacity-0 inset-0 flex items-center justify-center">
                            <AddTabImage edit={true} setField={setFieldValue} fieldName="img" />
                          </div>
                        </>
                      )) || (
                        <div className="absolute inset-0 flex items-center justify-center">
                          <AddTabImage fieldName="img" setField={setFieldValue} />
                        </div>
                      )}
                    </div>
                    {values &&
                      values.pgTabItemLng &&
                      values?.pgTabItemLng.map((item, index) => (
                        <FieldArray
                          key={index}
                          name="pgTabItemLng"
                          render={(arrayHelpers) => (
                            <>
                              {languageId === item.languageId && (
                                <>
                                  <label>
                                    <Field
                                      className="border rounded  w-full  p-2"
                                      type="text"
                                      placeholder={t('Add Title')}
                                      name={`pgTabItemLng[${index}].title`}
                                    />
                                  </label>
                                  <label>
                                    <RichTextEditor
                                      fieldName={`pgTabItemLng[${index}].text`}
                                      fieldValue={values.pgTabItemLng[index].text}
                                      setFieldValue={setFieldValue}
                                    />
                                    {/* <Field
                                      className='border rounded  w-full  p-2'
                                      as='textarea'
                                      rows='10'
                                      placeholder='Add Content'
                                      name={`pgTabItemLng[${index}].text`}
                                    /> */}
                                  </label>
                                </>
                              )}
                            </>
                          )}
                        />
                      ))}
                  </div>
                  <label className="mt-2 block">
                    <Field
                      type="number"
                      name="orderNum"
                      placeholder="Order Number"
                      className="border rounded  w-full  p-2"
                    />
                  </label>
                </Modal>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};
