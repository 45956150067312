import axios from 'axios';

export const getLanguages = async () => {
  const query = await axios.post('/Language/GetAll');
  const data = query.data.data;
  const isDone = query.data.isDone;
  return { data, isDone };
};

export const getPages = async (e) => {
  const initialData = {
    pageNumber: 1,
    pageSize: 1,
    isPaging: false,
    langId: 0,
  };
  const query = await axios.post('/PgPage/GetAll', initialData);
  const data = query.data.data;
  const isDone = query.data.isDone;
  const paginationResult = query.data.paginationResult;
  return { data, isDone, paginationResult };
};

export const getSections = async (e) => {
  const query = await axios.post('/PgSection/GetAllBy', e.queryKey[1]);
  const data = query.data.data;
  const isDone = query.data.isDone;
  const paginationResult = query.data.paginationResult;
  return { data, isDone, paginationResult };
};

export const getSectionById = async (e) => {
  const { id } = e.queryKey[1];
  console.log('e', id);
  const { data } = await axios.get(`/PgSection/Get?id=${id}`);
  return data;
};

export const addSection = async (e) => {
  console.log('e', e);
  const { data } = await axios.post('/PgSection/Add', e);
  return data;
};

export const deleteSection = async (e) => {
  const { data } = await axios.post('/PgSection/Delete', e);
  return data;
};
export const editSection = async (e) => {
  const { data } = await axios.post('/PgSection/Edit', e);
  return data;
};

export const toggleStatusSection = async (e) => {
  const mutation = await axios.post('/PgSection/ChangeStatus', e);
  const data = mutation.data.data;
  const isDone = mutation.data.isDone;
  return { data, isDone };
};

export const addCardItem = async (e) => {
  console.log('e', e);
  const { data } = await axios.post('/PgSection/AddCardItem', e);
  return data;
};

export const saveSectionOrder = async (e) => {
  const { data } = await axios.post('/PgSection/EditOrderNumber', e);
  return data;
};
