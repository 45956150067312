import React from 'react';

export default function LanguageList(props) {
  const { languages, languageId, setLanguageId, className, children } = props;

  return (
    <>
      {languages && (
        <div className={className ? className : 'flex'}>
          {languages
            ?.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))
            .map((language) => (
              <div
                key={language.id}
                className={`flex justify-center items-center space-x-2 ${
                  languageId === language.id ? 'text-pagetabhover border-pagetabhover' : ''
                }   hover:text-pagetabhover pb-4 px-4 z-50 border-b-2 hover:border-pagetabhover transition duration-300 ease-in-out cursor-pointer `}
                onClick={(e) => setLanguageId(language.id)}
              >
                <span>{language.name}</span>
              </div>
            ))}
          {children}
        </div>
      )}
    </>
  );
}
