import { Field, FieldArray, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import Modal from '../../../SharedComponents/Modal';
import PlusCircle from '../../../SharedComponents/Svg/PlusCircle';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Loading from '../../../SharedComponents/Loading';
import { addMenuItem, getLanguages } from './ApiMenu';
import PagesList from './PagesList';
import { useTranslation } from 'react-i18next';
import Notification from '../../../SharedComponents/Notification';
import LanguageList from '../../../SharedComponents/LanguageList';

export default function AddSubMenuItem(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { id } = props.id ? props : 1;
  const { typeId, activeMenu, parentId } = props;
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  // Mutate Data to Create New Page
  const addSubMenuItemMutation = useMutation(addMenuItem, {
    onSuccess: async (data) => {
      if (data.isDone) {
        queryClient.invalidateQueries(['Menu']);
        queryClient.invalidateQueries(['menuByParent']);
        setIsOpen(false);
      }
    },
  });

  return (
    <>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="bg-blue-100 text-blue-800 hover:bg-blue-200  p-1 rounded transition duration-300 ease-in-out"
      >
        <PlusCircle className="w-5 h-5" />
      </button>
      {addSubMenuItemMutation.isSuccess && <Notification message={t('Sub Menu Item Added, Successfullly')} />}

      {isOpen && (
        <ModalContainer
          id={id}
          typeId={typeId}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          activeMenu={activeMenu}
          parentId={parentId}
          addSubMenuItemMutation={addSubMenuItemMutation}
        />
      )}
    </>
  );
}

const ModalContainer = (props) => {
  const { isOpen, setIsOpen, activeMenu, parentId, addSubMenuItemMutation } = props;
  const [languageId, setLanguageId] = useState(1);
  const { t, i18n } = useTranslation();
  // Query Data To Fetch Languages
  const { data, isLoading, isError } = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });
  const [activePage, setActivePage] = useState();
  // create a loop for localisation
  const muMenuItemLngs = data?.data?.map((language) => ({
    languageId: language.id,
    title: '',
    description: '',
  }));
  const initialValues = {
    url: activePage ? activePage.uniqueUrl : '',
    orderNum: 0,
    typeId: activeMenu,
    loadCategoryMenu: true,
    isVariation: true,
    parentId: parentId,
    muMenuItemLngs: activePage ? activePage.PgPageLngs : muMenuItemLngs,
  };

  const onSubmit = async (e) => {
    // if (e.parentId === 0) {
    //   delete e.parentId;
    // }
    // console.log('e', e);

    // const newData = {
    //   parentId: e.parentId === 0 ? null : e.parentId,
    //   ...e,
    // };
    try {
      await addSubMenuItemMutation.mutate(e);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {}, [activePage]);
  return (
    <>
      {data && data.data && (
        <Formik onSubmit={onSubmit} validateOnBlur={false} validateOnChange={false} initialValues={initialValues}>
          {({ values, setFieldValue, errors }) => {
            return (
              <Form>
                <Modal
                  className="sm:max-w-3xl"
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  onSubmit={onSubmit}
                  title={t('Add Item')}
                  isLoading={addSubMenuItemMutation.isLoading}
                  add={true}
                >
                  {isLoading && <Loading />}
                  {isError && 'error'}
                  {data && (
                    <LanguageList
                      className="flex transform translate-y-1 relative z-10 mb-4"
                      languages={data?.data}
                      languageId={languageId}
                      setLanguageId={setLanguageId}
                    >
                      <hr className="border w-full absolute bottom-0 left-0 z-0" />
                    </LanguageList>
                  )}
                  <Field name="typeId" type="number" className="hidden" />
                  {/* Submit Form */}
                  <div className="grid grid-cols-2 gap-6">
                    <div>
                      {values &&
                        values.muMenuItemLngs &&
                        values?.muMenuItemLngs.map((asset, index) => (
                          <FieldArray
                            key={index}
                            name="muMenuItemLngs"
                            render={(arrayHelpers) => (
                              <>
                                {languageId === asset.languageId && (
                                  <>
                                    <label>
                                      <b
                                        className={`font-semibold block text-gray-700 ${
                                          i18n.language === 'en' ? '' : 'text-right'
                                        }`}
                                      >
                                        {t('Title')}
                                      </b>
                                      <Field
                                        className="border rounded  w-full mb-4 p-1"
                                        type="text"
                                        name="title"
                                        name={`muMenuItemLngs[${index}].title`}
                                      />
                                    </label>
                                  </>
                                )}
                              </>
                            )}
                          />
                        ))}
                      {/* <SelectMenuParent
                        languageId={languageId}
                        activeMenu={activeMenu}
                        FieldName='parentId'
                      /> */}
                      <label>
                        <b
                          className={`font-semibold block text-gray-700 ${i18n.language === 'en' ? '' : 'text-right'}`}
                        >
                          {t('Url')}
                        </b>
                        <Field className="border rounded  w-full mb-4 p-1" type="text" name="title" name={`url`} />
                      </label>
                    </div>
                    <div>
                      <PagesList
                        setFieldValue={setFieldValue}
                        activePage={activePage}
                        setActivePage={setActivePage}
                        languageId={languageId}
                      />
                    </div>
                  </div>
                </Modal>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};
