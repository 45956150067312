import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import Modal from '../../../../SharedComponents/Modal';
import Trash from '../../../../SharedComponents/Svg/Trash';
import { deleteFormQuestionOptionsType } from '../../ApiForms';

export default function DeleteFormQuestionOptionsType(props) {
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const { id } = props;
  const { mutate, data, isLoading } = useMutation(deleteFormQuestionOptionsType, {
    onSuccess: () => {
      queryClient.invalidateQueries(['formQuestionOptionType']);
    },
  });
  const onSubmit = async () => {
    try {
      await mutate({ id });
      if (data?.isDone) {
        setIsOpen(false);
      }
    } catch (error) {
      console.log('error', error);
    }
  };
  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="bg-red-100 text-red-800 hover:bg-red-200  p-1 rounded transition duration-300 ease-in-out"
      >
        <Trash className="w-5 h-5" />
      </button>
      <Formik
        initialValues={{ id: id }}
        onSubmit={(values, { resetForm }) => {
          onSubmit(values);
          resetForm();
        }}
      >
        <Form>
          <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            onSubmit={onSubmit}
            confirmTitle={t('Delete')}
            title={t('Delete form question type')}
            isLoading={isLoading}
            isDone={data?.isDone}
          >
            <Field
              style={{ textIndent: '10px' }}
              placeholder={t('EnterName')}
              className="border rounded  w-full mb-8 p-1 hidden"
              name="id"
              type="text"
            />
            <div className="bg-white ">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  {/* <!-- Heroicon name: outline/exclamation --> */}
                  <svg
                    className="h-6 w-6 text-red-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <div className="">
                    <p className="text-base font-normal text-gray-400 whitespace-pre-line">
                      {t(
                        'Are you sure you want to delete this form question type? All the data will be permanently removed from our servers forever. This action cannot be undone'
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </Form>
      </Formik>
    </>
  );
}
