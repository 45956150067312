import { DateTime } from 'luxon';
import React from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';

export default function DownloadFormAnswer(props) {
  const { fileHeaders, data, fileName, languageId } = props;
  const { t } = useTranslation();
  const filebody = data.map((item) => {
    const fileHeader = fileHeaders.map((th) =>
      th.id === 0
        ? { [th.frmQuestionLngs.filter((e) => e.languageId === languageId)[0]?.title]: item.createdByName }
        : th.id === 'date'
        ? {
            [th.frmQuestionLngs.filter((e) => e.languageId === languageId)[0]?.title]: DateTime.fromISO(
              item?.createDate
            ).toISODate(),
          }
        : item?.answer?.filter((e) => e.questionId === th.id)[0]?.question.qtypeId === 4
        ? {
            [th.frmQuestionLngs.filter((e) => e.languageId === languageId)[0]
              ?.title]: `https://rate2020cdn.key-frame.cf/images/${
              item?.answer?.filter((e) => e.questionId === th.id)[0]?.answer
            }`,
          }
        : {
            [th.frmQuestionLngs.filter((e) => e.languageId === languageId)[0]?.title]: item?.answer?.filter(
              (e) => e.questionId === th.id
            )[0]?.answer,
          }
    );
    const newObject = Object.assign({}, ...fileHeader);

    return newObject;
  });

  const csvReport = {
    data: filebody,
    headers: fileHeaders.map((e) => ({
      label: e.frmQuestionLngs.filter((e) => e.languageId === languageId)[0]?.title,
      key: e.frmQuestionLngs.filter((e) => e.languageId === languageId)[0]?.title,
    })),
    filename: `${fileName}.csv`,
  };

  return (
    <>
      <CSVLink {...csvReport}>
        <span className="bg-darkblue hover:bg-darkbluehover text-white py-2 rounded px-4">{t('Download CSV')}</span>
      </CSVLink>
    </>
  );
}
