import { Field, FieldArray, Form, Formik, isEmptyArray } from 'formik';
import { useEffect, useState } from 'react';
import Modal from '../../SharedComponents/Modal';
import PlusCircle from '../../SharedComponents/Svg/PlusCircle';
import React, { useCallback } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Loading from '../../SharedComponents/Loading';
import { addForm, editForm, getLanguages } from './ApiForms';
import DocumentDuplicate from '../../SharedComponents/Svg/DocumentDuplicate';
import PecnilAlt from '../../SharedComponents/Svg/PencilAlt';
import { useTranslation } from 'react-i18next';
import LanguageList from '../../SharedComponents/LanguageList';

export default function EditForm(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { form } = props;

  return (
    <>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="bg-green-100 text-green-800 hover:bg-green-200  p-1 rounded transition duration-300 ease-in-out"
      >
        <PecnilAlt className="w-5 h-5" />
      </button>

      {isOpen && <ModalContainer form={form} isOpen={isOpen} setIsOpen={setIsOpen} />}
    </>
  );
}

const ModalContainer = (props) => {
  const { isOpen, setIsOpen, form } = props;
  const [languageId, setLanguageId] = useState(1);
  const [uploadSucess, setUploadSucess] = useState(false);
  const { t, i18n } = useTranslation();

  // Query Data To Fetch Languages
  const { data, isLoading, isError } = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });

  const initialValues = form;
  const queryClient = useQueryClient();
  // Mutate Data to Create New Page
  const mutation = useMutation(editForm, {
    onSuccess: async (data) => {
      if (data.isDone) {
        queryClient.invalidateQueries(['forms']);

        setIsOpen(false);
      }
    },
  });

  const onSubmit = async (e) => {
    try {
      await mutation.mutate(e);
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <>
      {data && data.data && (
        <Formik onSubmit={onSubmit} initialValues={initialValues}>
          {({ values, setFieldValue }) => {
            console.log('values', values);
            return (
              <Form>
                <Modal isOpen={isOpen} setIsOpen={setIsOpen} onSubmit={onSubmit} title="Create Form" add={true}>
                  {isLoading && <Loading />}
                  {isError && 'error'}
                  {data && (
                    <LanguageList
                      className="flex transform translate-y-1 relative z-10 mb-4"
                      languages={data?.data}
                      languageId={languageId}
                      setLanguageId={setLanguageId}
                    >
                      <hr className="border w-full absolute bottom-0 left-0 z-0" />
                    </LanguageList>
                  )}

                  {/* Submit Form */}

                  {values &&
                    values.frmFormLngs &&
                    values?.frmFormLngs.map((asset, index) => (
                      <FieldArray
                        key={index}
                        name="frmFormLngs"
                        render={(arrayHelpers) => (
                          <>
                            {languageId === asset.languageId && (
                              <>
                                <label className={`${i18n.language === 'en' ? '' : 'text-right'}`}>
                                  <b className="font-semibold block text-gray-700">{t('Title')}</b>
                                  <Field
                                    className="border rounded  w-full mb-4 p-1"
                                    type="text"
                                    name={`frmFormLngs[${index}].title`}
                                  />
                                </label>
                                <label className={`${i18n.language === 'en' ? '' : 'text-right'}`}>
                                  <b className="font-semibold block text-gray-700">{t('Submit Button Text')}</b>
                                  <Field
                                    className={`border rounded  w-full mb-4 p-1`}
                                    name={`frmFormLngs[${index}].submitBtnText`}
                                    type="text"
                                  />
                                </label>

                                <label className={`${i18n.language === 'en' ? '' : 'text-right'}`}>
                                  <b className="font-semibold block text-gray-700">{t('Reject Response Message')}</b>
                                  <Field
                                    className="border rounded  w-full mb-4 p-1"
                                    name={`frmFormLngs[${index}].rejectResponseMessage`}
                                    type="text"
                                  />
                                </label>
                                <label className={`${i18n.language === 'en' ? '' : 'text-right'}`}>
                                  <b className="font-semibold block text-gray-700">{t('Action Failed Message')}</b>
                                  <Field
                                    className="border rounded  w-full mb-4 p-1"
                                    name={`frmFormLngs[${index}].actionFailedMessage`}
                                    type="text"
                                  />
                                </label>
                                <label className={`${i18n.language === 'en' ? '' : 'text-right'}`}>
                                  <b className="font-semibold block text-gray-700">{t('Action Success Message')}</b>
                                  <Field
                                    className="border rounded  w-full mb-4 p-1"
                                    name={`frmFormLngs[${index}].actionSuccessMessage`}
                                    type="text"
                                  />
                                </label>
                              </>
                            )}
                          </>
                        )}
                      />
                    ))}

                  <label className={`${i18n.language === 'en' ? '' : 'text-right'} relative`}>
                    <b className="font-semibold block text-gray-700">{t('Unique Url')}</b>
                    <Field className="border rounded  w-full mb-4 p-1" name={`uniqueUrl`} type="text" />
                  </label>
                </Modal>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};
