import newsletter from './newsletter';
import settings from './settings';
import users from './users';
import pages from './pages';
import menus from './menus';
import question from './question';
import forms from './forms';
import faq from './faq';
import polls from './polls';
import roles from './roles';
import languages from './languages';
import permissions from './permissions';
import statisticsSection from './statisticsSection';
import assets from './assets';
import collapse from './collapse';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...settings,
  ...users,
  ...newsletter,
  ...pages,
  ...menus,
  ...question,
  ...forms,
  ...faq,
  ...polls,
  ...roles,
  ...languages,
  ...permissions,
  ...statisticsSection,
  ...assets,
  ...collapse,
  Profile: 'الملف الشخصي',
  Products: 'المنتجات',
  'Log out': 'تسجيل خروج',
  'Pages Count By Category': 'عدد الصفحات حسب الفئة',
  'Users Count By Role': 'عدد المستخدمين حسب الوظيفة',
  'User Count By Role': 'عدد المستخدمين حسب الوظيفة',
  'User Count By Status': 'عدد المستخدمين حسب الحالة',
  'Pages Count By Status': 'عدد الصفحات حسب الحالة',
  '# of pages': '# الصفحات',
  '# of Users': '# المستخدمين',
  Blogs: 'المدونات',
  News: 'أخبار',
  Events: 'الأحداث',
  Draft: 'مسودة',
  Published: 'نشرت',
  Active: 'متصل',
  'Not Active': 'غير متصل',
  Images: 'الصور',
  Image: 'صورة',
  Audio: 'صوتيات',
  Video: 'الفيديو',
  Youtube: 'يوتيوب',
  Vimeo: 'فيميو',
  Link: 'رابط',
  Document: 'وثيقة',
  'Assets Have Been Moved Successfully': 'تم نقل الملف بنجاح',
  'Please Select Assets To Move': 'يرجى تحديد الملف للتحرك',
  Assets: 'الملفات',
  Dashboard: 'لوحة التحكم',
  'Upload New Files': 'إضافة ملفات جديد',
  'Upload New File': 'إضافة ملف جديد',
  'Uploading ...': 'تحميل ...',
  'File Uploaded Successfully': 'تم رفع الملف بنجاح',
  URL: 'الرابط',
  Title: 'العنوان',
  alt: 'العنوان البديل',
  Folder: 'مجلد',
  Description: 'الوصف',
  Cancel: 'إلغاء',
  Save: 'حفظ',
  'Move To': 'نقل إلى',
  'Create Folder': 'أنشئ مجلد',
  'File Upload to server successfully': 'تم تحميل الملف بنجاح',
  'Release to drop the files here': 'اسحب الملف هنا',
  'Upload file or drag and drop': 'حمل الملف أو اسحب هنا',
  Total: 'المجموع',
  'up to 10MB': 'يصل إلى 10 ميغابايت',
  'Audio File': 'الملف الصوتي',
  Options: 'الخيارات',
  Delete: 'حذف',
  Edit: 'تعديل',
  'Are you sure you want to delete this Asset? All the data will be permanently removed from our servers forever. This action cannot be undone':
    'هل أنت متأكد أنك تريد حذف هذا الملف؟ ستتم إزالة جميع البيانات بشكل دائم من خوادمنا إلى الأبد. لا يمكن التراجع عن هذا الإجراء',
  'Are you sure you want to delete this File? All the data will be permanently removed from our servers forever. This action cannot be undone':
    'هل أنت متأكد أنك تريد حذف هذا الملف؟ ستتم إزالة جميع البيانات بشكل دائم من خوادمنا إلى الأبد. لا يمكن التراجع عن هذا الإجراء',
  'Delete Folder': 'احذف المجلد',
  'Edit Folder': 'تعديل المجلد',
  EnterName: 'أدخل الاسم',
  'Are you sure you want to delete this Folder? All the data will be permanently removed from our servers forever. This action cannot be undone':
    'هل أنت متأكد أنك تريد حذف هذا المجلد؟ ستتم إزالة جميع البيانات بشكل دائم من خوادمنا إلى الأبد. لا يمكن التراجع عن هذا الإجراء',
  Update: 'تحديث',
  'Enter Name': 'أدخل الاسم',
  Name: 'الاسم',
  Hidden: 'اخفاء',
  Items: 'عناصر',
  'Asset Deleted': 'الملف محذوف',
  'Delete Asset': 'حذف اللملف',
  'Cannot Delete, This Asset is used somewhere else': 'لا يمكن حذف، يتم استخدام هذا الملف في مكان آخر',
  'am Hidden': 'أنا مخفي',
  Preview: 'عرض',
  View: 'عرض',
  'Your browser does not support the video tag.': 'متصفحك الحالي لا يدعم تشغيل الفيديو.',
  entries: 'مداخل',
  'Galley Albums': 'ألبومات الصور',
  'Gallery Albums List': 'قائمه الألبومات ',
  'Create Gallery Album': 'إنشاء معرض ألبوم ',
  'Order Number is Duplicated Kindly Change it': 'رقم الطلب متكرر من فضلك قم بتغيره',
  'Image Id': 'رقم الصورة',
  'Order Number': 'رقم الطلب',
  'Delete Galley Album': 'حذف معرض الصور ',
  'Are you sure you want to delete this Gallery Album? All the data will be permanently removed from our servers forever. This action cannot be undone':
    'هل أنت متأكد أنك تريد حذف معرض الصور هذا؟سيتم إزالتها بشكل دائم من خوادمنا إلى الأبد.لا يمكن التراجع عن هذا الإجراء',
  'Sorry, This Gallery Album have Data Inside, Please Delete and Retry':
    'عذرا، هذا معرض الصور يحتوي على بيانات داخله ، يرجى حذف وإعادة المحاولة',
  'Button Next Text': 'نص الزر التالي',
  'Button Previous Text': 'نص الزر السابق',
  'Button Close Text': 'نص الزر الاغلاق',
  Keywords: 'الكلمات الدالة',
  'Button Previous Link': 'زر الرابط السابق',
  'Button Close Link': 'زر الرابط الاغلاق',
  'Create Gallery Item': 'إنشاء عنصر في معرض الصور',
  'Replace Image': 'استبدال الصورة',
  'Choose Image': 'اختر صورة',
  'GO BACK': 'الرجوع',
  'Replace Video': 'استبدال الفيديو',
  'Choose Video': 'اختيار الفيديو',
  'Button Next Link': 'زر الرابط السابق',
  'Add Language': 'إضافة لغة',
  Code: 'كود',
  'Are you sure you want to delete this language? All the data will be permanently removed from our servers forever. This action cannot be undone':
    'هل أنت متأكد أنك تريد حذف هذه اللغة؟سيتم إزالتها بشكل دائم من خوادمنا إلى الأبد.لا يمكن التراجع عن هذا الإجراء',
  'Language Edit Successfully': 'تم تعديل اللغة بنجاح',
  'Delete language': 'حذف اللغة',
  'Language Delete Successfully': 'تم حذف اللغة بنجاح',
  'Create Language': 'إنشاء لغة',
  Language: 'اللغة',
  'Language List': 'قائمة اللغات',
  Show: 'عرض',
  Search: 'بحث',
  Previous: 'السابق',
  'Category Created Successfully': 'تم إنشاء الفئة بنجاح',
  'Add Category': 'إضافة فئة',
  'Create Category': 'إنشاء فئة',
  'Categories List': 'قائمة الفئات',
  Showing: 'عرض',
  of: 'من',
  results: 'النتائج',
  'Delete Category': 'حذف الفئة',
  'Are you sure you want to delete this Category? All the data will be permanently removed from our servers forever. This action cannot be undone':
    'هل أنت متأكد أنك تريد حذف هذه الفئة؟سيتم إزالتها بشكل دائم من خوادمنا إلى الأبد.لا يمكن التراجع عن هذا الإجراء',
  'Create Faq': 'إنشاء أسئلة وأجوبة',
  Question: 'السؤال',
  Answer: 'الاجابه',
  'Delete Faq': 'حذف الاسئله الشائعه',
  'Are you sure you want to delete this Faq? All the data will be permanently removed from our servers forever. This action cannot be undone':
    'هل أنت متأكد أنك تريد حذف هذه الأسئلة الشائعة؟سيتم إزالتها بشكل دائم من خوادمنا إلى الأبد.لا يمكن التراجع عن هذا الإجراء',
  galleryalbums: 'الألبوم',
  pages: 'الصفحات',
  Pages: 'الصفحات',
  Categories: 'الفئات',
  'Add New Folder': 'إضافة مجلد جديد',
  'View Asset': 'عرض الملف',
  'Sign in': 'تسجيل الدخول',
  'Username or email': 'اسم المستخدم أو البريد الالكتروني',
  Password: 'كلمه السر',
  'Remeber me': 'تذكرنى',
  'Reset password': 'إعادة تعيين كلمة المرور',
  'Login with your social media account.': 'تسجيل الدخول باستخدام السوشيل ميديا.',
  'List Updated': 'تم تحديث القائمه',
};
