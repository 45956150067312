import { Field, FieldArray, Form, Formik } from 'formik';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import Notification from '../../../../SharedComponents/Notification';
import SectionHeader from '../SectionHeader';
import LoadingCircle from '../../../../SharedComponents/Svg/LoadingCircle';
import { editModal } from '../Modal/ApiModal';
import FormsList from './FormsList';
import { editForm } from './ApiForm';

export default function FormSection(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { id, section, languages, editSectionMutation, provided } = props;
  const [languageId, setLanguageId] = useState(1);
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();

  const editFormMutation = useMutation(editForm, {
    onSuccess: async (data) => {
      if (data.isDone) {
        queryClient.invalidateQueries(['sections']);
      }
    },
  });

  const onSaveSettings = async (values) => {
    try {
      await editFormMutation.mutate(values.pgSectionForms);
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <div className="block bg-white w-full shadow border mb-4">
      {editFormMutation.isSuccess && <Notification message={t('Section Updated')} />}
      {editFormMutation.isError && <Notification error={true} message={t('Network Error')} />}
      <SectionHeader
        provided={provided}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        section={section}
        languageId={languageId}
      />
      <Formik initialValues={section}>
        {({ values, setFieldValue, setValues }) => {
          return (
            <>
              <>
                <div className={`bg-white px-4  overflow-hidden      ${isOpen ? 'py-3 h-full  ' : '  py-0 h-0'}`}>
                  <div className="">
                    <div className="flex justify-between relative items-center">
                      <div className="flex transform -translate-y-1 relative z-10">
                        {t('Section Type')} : {t('Form')}
                      </div>
                      <div>
                        <div>
                          {languages && languages.data && (
                            <div className="flex justify-end transform translate-y-1 relative z-10 mb-4">
                              {languages?.data?.data.map((language) => (
                                <div
                                  key={language.id}
                                  className={`flex justify-center items-center space-x-2 ${
                                    languageId === language.id ? 'text-pagetabhover border-pagetabhover' : ''
                                  }   hover:text-pagetabhover pb-4 px-4 z-50 border-b-2 hover:border-pagetabhover transition duration-300 ease-in-out cursor-pointer `}
                                  onClick={(e) => setLanguageId(language.id)}
                                >
                                  <span>{language.name}</span>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                      <hr className="border w-full absolute top-0 left-0 z-0 mt-11" />
                    </div>
                  </div>
                  <FormsList
                    setFieldValue={setFieldValue}
                    formId={values.pgSectionForms.formId}
                    languageId={languageId}
                  />
                  <div className="flex justify-end mt-4">
                    <button
                      type="button"
                      disabled={editFormMutation.isLoading}
                      onClick={() => {
                        onSaveSettings(values);
                      }}
                      className={`bg-darkblue relative focus:outline-none space-x-1 ${
                        i18n.language === 'en' ? '' : 'space-x-reverse'
                      } text-sm font-bold text-white flex items-center hover:bg-darkbluehover shadow  px-4 py-2 rounded transition duration-300 ease-in-out`}
                    >
                      {t('Update Section')}
                      {editFormMutation.isLoading && <LoadingCircle />}
                    </button>
                  </div>
                </div>
              </>
            </>
          );
        }}
      </Formik>
    </div>
  );
}
