import { Field, FieldArray, Form, Formik, isEmptyArray } from 'formik';
import { useEffect, useState } from 'react';
import Modal from '../../SharedComponents/Modal';
import PlusCircle from '../../SharedComponents/Svg/PlusCircle';
import React, { useCallback } from 'react';
import DropZone from './DropZone';
import ImageList from './ImageList';
import axios from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { addAsset, editAsset, getFolders, getLanguages, uploadMedia } from './ApiAssets';
import Loading from '../../SharedComponents/Loading';
import PecnilAlt from '../../SharedComponents/Svg/PencilAlt';
import { useTranslation } from 'react-i18next';
import LanguageList from '../../SharedComponents/LanguageList';

export default function EditUploadAsset(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [acceptFiles, setAcceptFiles] = useState('');
  const { id } = props.id ? props : 1;
  const { typeId, asset } = props;

  return (
    <>
      <button
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className="bg-green-100 text-green-800 hover:bg-green-200  p-1 rounded transition duration-300 ease-in-out"
      >
        <PecnilAlt className="w-5 h-5" />
      </button>

      {isOpen && <ModalContainer id={id} typeId={typeId} isOpen={isOpen} setIsOpen={setIsOpen} asset={asset} />}
    </>
  );
}

const ModalContainer = (props) => {
  const { isOpen, setIsOpen, id, typeId, asset } = props;
  const [languageId, setLanguageId] = useState(1);
  const [uploadSucess, setUploadSucess] = useState(false);
  const { t, i18n } = useTranslation();
  // localState for image uploaded
  const [images, setImages] = useState([]);
  const [file, setFile] = useState();
  const [filePath, setFilePath] = useState(asset.fileUrl);
  const [uploading, setUploading] = useState(false);
  const mediaUpload = useMutation(uploadMedia, {
    onSuccess: (e) => {
      setFilePath(e.data.path);
      setUploadSucess(true);
      setUploading(false);
    },
  });
  // onDrop function
  const onDrop = useCallback((acceptedFiles) => {
    setUploading(true);
    // loop through the accepted Files
    acceptedFiles.map((file) => {
      // Initialize FileReader browser API
      const reader = new FileReader();
      // onload callback gets called after the reader reads the file data
      reader.onload = function (e) {
        console.log(e.target);
        console.log(e);
        // add the image into the state. Since FileReader reading process is asynchronous, its better to get the latest snapshot state (i.e., prevState) and update it.
        setImages((prevState) => [{ id: 1, src: e.target.result }]);
      };
      // Read the file as Data URL (since we accept only images)
      reader.readAsDataURL(file);

      return file;
    });
    // this callback will be called after files get dropped, we will get the acceptedFiles. If you want, you can even access the rejected files too
    console.log(acceptedFiles);
    setFile(acceptedFiles);

    const handleServer = async () => {
      const formData = new FormData();
      formData.append('file', acceptedFiles[0]);

      try {
        await mediaUpload.mutate(formData);
      } catch (error) {
        console.log('error', error);
      }
    };
    handleServer();
  }, []);

  // useEffect to not let the Component Reload
  useEffect(() => {}, [filePath]);

  // Query Data To Fetch Languages
  const { data, isLoading, isError } = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });
  // Query Data to Fetch Foldiers
  const folders = useQuery(
    [
      'assetFolders',
      {
        typeId: typeId,
        paginationFilter: {
          pageNumber: 0,
          pageSize: 0,
          isPaging: false,
          langId: 0,
        },
      },
    ],
    getFolders,
    {
      staleTime: 99900,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    }
  );
  // Handle Multiple Language Content in the Page
  const astAssetLngs = data?.data?.map((language) => ({
    languageId: language.id,
    title: '',
    description: '',
    alt: '',
  }));
  //  Initial Values for Page Data
  const initialValues = asset;
  const queryClient = useQueryClient();
  const { mutate } = useMutation(editAsset, {
    onSuccess: () => {
      queryClient.invalidateQueries(['assetFolder']);
      setIsOpen(false);
    },
  });
  const onSubmit = async (e) => {
    const newData = {
      ...e,
      fileUrl: `${filePath}`,
    };
    console.log(e);
    try {
      await mutate(typeId === 4 || typeId === 5 || typeId === 6 ? e : newData);
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <>
      {data && data.data && (
        <Formik onSubmit={onSubmit} initialValues={initialValues}>
          {({ values, setFieldValue }) => {
            return (
              <Form>
                <Modal
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  onSubmit={onSubmit}
                  title={t('Upload New File')}
                  add={true}
                >
                  {isLoading && <Loading />}
                  {isError && 'error'}
                  <LanguageList
                    className="flex transform translate-y-1 relative z-10 mb-4"
                    languages={data?.data}
                    languageId={languageId}
                    setLanguageId={setLanguageId}
                  >
                    <hr className="border w-full absolute bottom-0 left-0 z-0" />
                  </LanguageList>
                  {typeId !== 4 && typeId !== 5 && typeId !== 6 && (
                    <div className=" focus:outline-none rounded border-dashed border-2 mb-4">
                      {/* Drag amd Drop Component */}
                      <DropZone
                        className="focus:outline-none"
                        onDrop={onDrop}
                        accept={`  ${typeId === 1 ? 'image/jpeg, image/png,image/jpg' : ' '}
                      ${typeId === 2 ? 'audio/*' : ' '}
                      ${typeId === 3 ? 'video/*' : ' '}
                    
                      ${
                        typeId === 7
                          ? 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/docx,application/pdf,text/plain,application/msword,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                          : ' '
                      }
                     `}
                        images={images}
                        multiple={false}
                      />
                    </div>
                  )}
                  {uploading && (
                    <div className="relative pt-1">
                      <div className="overflow-hidden h-4 mb-4 text-xs flex rounded bg-gray-200">
                        <div
                          className={`shadow-none flex ${
                            uploading ? 'w-full' : 'w-0 '
                          } flex-col  transition-all ease-in-out animate-pulse duration-3000 text-center whitespace-nowrap text-white justify-center bg-orange`}
                        >
                          <smal className=" text-center w-full">{t('Uploading ...')} </smal>
                        </div>
                      </div>
                    </div>
                  )}
                  {!uploading && images.length > 0 && typeId === 1 && <ImageList images={images} />}
                  {uploadSucess && (
                    <>
                      <small className="bg-lightgreen text-white block p-1 text-center mb-4">
                        {t('File Uploaded Successfully')}
                      </small>
                    </>
                  )}
                  {/* Submit Form */}
                  <label>
                    <b
                      className={`${
                        typeId === 4 || typeId === 5 || typeId === 6 ? '' : 'hidden'
                      } font-semibold block text-gray-700 ${i18n.language === 'en' ? '' : 'text-right'}`}
                    >
                      URL
                    </b>
                    <Field
                      name="fileUrl"
                      className={`${
                        typeId === 4 || typeId === 5 || typeId === 6 ? '' : 'hidden'
                      } border rounded  w-full mb-4 p-1`}
                      type="text"
                    />
                  </label>

                  {values &&
                    values.astAssetLngs &&
                    values?.astAssetLngs.map((asset, index) => (
                      <FieldArray
                        key={index}
                        name="astAssetLngs"
                        render={(arrayHelpers) => (
                          <>
                            {languageId === asset.languageId && (
                              <>
                                <label>
                                  <b
                                    className={`font-semibold block text-gray-700 ${
                                      i18n.language === 'en' ? '' : 'text-right'
                                    }`}
                                  >
                                    {t('Title')}
                                  </b>
                                  <Field
                                    className="border rounded  w-full mb-4 p-1"
                                    type="text"
                                    name="title"
                                    name={`astAssetLngs[${index}].title`}
                                  />
                                </label>
                                <label className={` ${typeId === 1 ? '' : 'hidden'}`}>
                                  <b
                                    className={`font-semibold block text-gray-700 ${
                                      i18n.language === 'en' ? '' : 'text-right'
                                    }`}
                                  >
                                    {t('alt')}
                                  </b>
                                  <Field
                                    className={`border rounded  w-full mb-4 p-1`}
                                    name={`astAssetLngs[${index}].alt`}
                                    type="text"
                                  />
                                </label>
                                <label className={` ${typeId === 1 ? '' : 'hidden'}`}>
                                  <b
                                    className={`font-semibold block text-gray-700 ${
                                      i18n.language === 'en' ? '' : 'text-right'
                                    }`}
                                  >
                                    {t('Description')}
                                  </b>
                                  <Field
                                    className="border rounded  w-full mb-4 p-1"
                                    name={`astAssetLngs[${index}].description`}
                                    type="text"
                                  />
                                </label>
                              </>
                            )}
                          </>
                        )}
                      />
                    ))}
                  <label>
                    <b className={`font-semibold block text-gray-700 ${i18n.language === 'en' ? '' : 'text-right'}`}>
                      {t('Folder')}
                    </b>
                    <Field className="border rounded  w-full mb-4 p-1" name="astFolderId" as="select">
                      {folders &&
                        folders.data &&
                        folders.data.data.map((e) => {
                          return (
                            <option key={e.id} value={parseInt(e.id)}>
                              {e.name}
                            </option>
                          );
                        })}
                    </Field>
                  </label>
                </Modal>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};
