import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import Modal from '../../../SharedComponents/Modal';
import PecnilAlt from '../../../SharedComponents/Svg/PencilAlt';
import PlusCircle from '../../../SharedComponents/Svg/PlusCircle';
import { addFolder, editFolder } from '../ApiAssets';

const ModalContainer = (props) => {
  const queryClient = useQueryClient();
  const { isOpen, setIsOpen, typeId, folder } = props;
  const { t, i18n } = useTranslation();
  const { mutate, data } = useMutation(editFolder, {
    onSuccess: () => {
      queryClient.invalidateQueries(['assetFolders']);
      setIsOpen(false);
    },
  });
  const onSubmit = async (e) => {
    try {
      await mutate(e);
    } catch (error) {
      console.log('error', error);
    }
  };
  return (
    <Formik
      initialValues={folder}
      onSubmit={(values, { resetForm }) => {
        onSubmit({ ...values });
        resetForm();
      }}
    >
      {({ values, setFieldValue }) => {
        return (
          <Form>
            <Modal isOpen={isOpen} setIsOpen={setIsOpen} title={t('Edit Folder')} add={true} confirmTitle={t('Update')}>
              <div className="flex flex-col">
                <label>
                  <b className={`font-semibold block text-gray-900 ${i18n.language === 'en' ? '' : 'text-right'}`}>
                    {t('Name')}
                  </b>
                  <Field
                    style={{ textIndent: '10px' }}
                    placeholder={t('Enter Name')}
                    className="border rounded  w-full mb-2 p-1"
                    name="name"
                    type="text"
                  />
                </label>
                <label className={`space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse ml-auto'}`}>
                  <Field
                    type="checkbox"
                    className="border rounded  mb-8 p-1"
                    name="isHidden"
                    //   value={values.isHidden}
                  />
                  <span>{t('Hidden')}</span>
                </label>
              </div>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};

export default function EditFolder(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const { typeId, folder, withText } = props;
  return (
    <>
      {withText ? (
        <button
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          className={`text-left flex items-center ${
            i18n.language === 'en' ? '' : 'space-x-reverse'
          } space-x-2 hover:bg-gray-100 px-2 py-1`}
        >
          <PecnilAlt className="w-4 h-4 " />
          <span>{t('Edit')} </span>
        </button>
      ) : (
        <button
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          className="bg-green-100 text-green-800 hover:bg-green-200  p-1 rounded transition duration-300 ease-in-out"
        >
          <PecnilAlt className="w-5 h-5" />
        </button>
      )}

      {isOpen && <ModalContainer typeId={typeId} folder={folder} isOpen={isOpen} setIsOpen={setIsOpen} />}
    </>
  );
}
