import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import Notification from '../../SharedComponents/Notification';
import { toggleStatusForm } from './ApiForms';

export default function ToggleFormStatus(props) {
  const { statusId, id } = props;
  console.log('statusId', statusId);
  const [activeToggle, setActiveToggle] = useState(statusId === 1 ? true : false);
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const toggleStatusFormMutation = useMutation(toggleStatusForm, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['users']);
    },
  });

  const handleClick = async (e) => {
    setActiveToggle(!activeToggle);
    const newData = { formId: id, statusId: activeToggle === true ? 0 : 1 };

    try {
      await toggleStatusFormMutation.mutate(newData);
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <>
      {toggleStatusFormMutation.isSuccess && <Notification message={t('Form Status, Updated')} />}
      <button onClick={handleClick} className="flex items-center cursor-pointer focus:outline-none">
        {/* <!-- toggle --> */}
        <div className="relative">
          {/* <!-- line --> */}
          <div
            className={` w-12 h-6 ${activeToggle ? 'bg-lightgreen' : 'bg-gray-400'}  rounded-full shadow-inner`}
          ></div>
          {/* <!-- dot --> */}
          <div
            className={`transition-all ease-in-out duration-700 absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 ${
              activeToggle ? 'right-0' : 'left-0'
            } `}
          ></div>
        </div>
        {/* <!-- label --> */}
      </button>
    </>
  );
}
