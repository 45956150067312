import { Field, FieldArray, Form, Formik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import Notification from '../../../../SharedComponents/Notification';
import ChevronRight from '../../../../SharedComponents/Svg/ChevronRight';
import LoadingCircle from '../../../../SharedComponents/Svg/LoadingCircle';
import { addCardItem, editSection } from '../ApiSections';
import DeleteSection from '../DeleteSection';
import EditSection from '../EditSection';
import SectionHeader from '../SectionHeader';
import ToggleSectionStatus from '../ToggleSectionStatus';
import AddCardItem from './AddCardItem';
import { editCard } from './ApiCard';
import CardItem from './CardItem';

export default function Card(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { section, languages, provided } = props;
  const [languageId, setLanguageId] = useState(1);
  const { t, i18n } = useTranslation();
  const nextBtnTypeIds = [
    { id: 0, name: 'Arrows' },
    { id: 1, name: 'Dots' },
    { id: 2, name: 'ArrowsAndDots' },
  ];
  const queryClient = useQueryClient();
  // Mutate Data to Create New Page
  const mutation = useMutation(editSection, {
    onSuccess: async (data) => {
      if (data.isDone) {
        queryClient.invalidateQueries(['sections']);
      }
    },
  });

  const editCardMutation = useMutation(editCard, {
    onSuccess: async (data) => {
      if (data.isDone) {
        queryClient.invalidateQueries(['sections']);
      }
    },
  });

  const onSubmit = async (values) => {
    console.log('section Submit', values);
    try {
      console.log(values);
      await mutation.mutate(values);
    } catch (error) {
      console.log('error', error);
    }
  };

  // Mutate Data to Create New Page
  const addCardItemMutation = useMutation(addCardItem, {
    onSuccess: async (data) => {
      if (data.isDone) {
        queryClient.resetQueries(['sections']);
        queryClient.resetQueries('sections');
      }
    },
  });

  const onSaveSettings = async (values) => {
    console.log('onSaveSettings', values.pgSectionCard);
    try {
      console.log(values);
      await editCardMutation.mutate(values.pgSectionCard);
    } catch (error) {
      console.log('error', error);
    }
  };
  return (
    <>
      {editCardMutation.isSuccess && <Notification message={t('Section Updated')} />}
      {editCardMutation.isError && <Notification error={true} message={t('Network Error')} />}
      <div className="block bg-white w-full shadow border mb-4">
        <SectionHeader
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          section={section}
          provided={provided}
          languageId={languageId}
        />

        <Formik initialValues={section}>
          {({ values, setFieldValue, setValues }) => {
            return (
              <>
                <div className={`bg-white px-4  overflow-hidden      ${isOpen ? 'py-3 h-full  ' : '  py-0 h-0'}`}>
                  <div className="">
                    <div className="flex justify-between relative items-center">
                      <div className="flex transform -translate-y-1 relative z-10">
                        {t('Section Type')} : {t('Card')}
                      </div>
                      <div>
                        <div>
                          {languages && languages.data && (
                            <div className="flex justify-end transform translate-y-1 relative z-10 mb-4">
                              {languages?.data?.data.map((language) => (
                                <div
                                  key={language.id}
                                  className={`flex justify-center items-center space-x-2 ${
                                    languageId === language.id ? 'text-pagetabhover border-pagetabhover' : ''
                                  }   hover:text-pagetabhover pb-4 px-4 z-50 border-b-2 hover:border-pagetabhover transition duration-300 ease-in-out cursor-pointer `}
                                  onClick={(e) => setLanguageId(language.id)}
                                >
                                  <span>{language.name}</span>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                      <hr className="border w-full absolute top-0 left-0 z-0 mt-11" />
                    </div>
                  </div>
                  <div className="bg-gray-100 p-4">
                    <div> {t('Section Settings')} </div>
                    <hr className="mt-2 mb-4 block" />
                    <div className="grid grid-cols-3 gap-6 items-center flex">
                      <div>
                        <label
                          className={`flex items-center space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}
                        >
                          <Field type="checkbox" name="pgSectionCard.showAsCarousel" className="w-4 h-4" />
                          <span>{t('Show as a carousel')}</span>
                        </label>
                      </div>
                      <div>
                        <Field
                          name="pgSectionCard.itemsCount"
                          type="number"
                          className="border w-full p-2 rounded"
                          placeholder="Homa Many in Scene"
                        />
                      </div>
                      <div>
                        <Field
                          className="border rounded p-2 w-full"
                          as="select"
                          name="pgSectionCard.nextBtnTypeId"
                          onChange={(e) => {
                            setFieldValue('pgSectionCard.nextBtnTypeId', parseInt(e.target.value));
                          }}
                        >
                          {nextBtnTypeIds.map((nextBtnTypeIds, index) => (
                            <option value={parseInt(nextBtnTypeIds.id)} key={nextBtnTypeIds.id}>
                              {t(nextBtnTypeIds.name)}
                            </option>
                          ))}
                        </Field>
                      </div>
                    </div>
                    <div className="flex justify-end mt-4">
                      <button
                        type="button"
                        disabled={editCardMutation.isLoading}
                        onClick={() => {
                          onSaveSettings(values);
                        }}
                        className={`bg-darkblue relative focus:outline-none space-x-1 ${
                          i18n.language === 'en' ? '' : 'space-x-reverse'
                        } text-sm font-bold text-white flex items-center hover:bg-darkbluehover shadow  px-4 py-2 rounded transition duration-300 ease-in-out`}
                      >
                        {t('Save Settings')}
                        {editCardMutation.isLoading && <LoadingCircle />}
                      </button>
                    </div>
                  </div>
                  {values &&
                    values?.pgSectionCard?.pgSectionCardItems &&
                    values?.pgSectionCard.pgSectionCardItems.map((pgSectionCardItem, index) => (
                      <FieldArray
                        key={index}
                        name="pgSectionCard.pgSectionCardItems"
                        render={(arrayHelpers) => (
                          <>
                            <CardItem
                              setFieldValue={setFieldValue}
                              languageId={languageId}
                              pgSectionCardItem={pgSectionCardItem}
                              pgSectionCardItemIndex={index}
                              pgSectionCardItemsHelper={arrayHelpers}
                            />
                          </>
                        )}
                      />
                    ))}

                  <div className={`flex justify-end py-4 space-x-4 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
                    <AddCardItem
                      pgSectionCardId={values.pgSectionCardId}
                      orderNum={values.pgSectionCard.pgSectionCardItems.length}
                      addCardItemMutation={addCardItemMutation}
                    />
                    <button
                      type="button"
                      onClick={() => {
                        onSubmit(values);
                      }}
                      className="bg-lightgreen py-2 px-2 flex justify-center text-center h-full text-sm font-bold text-white items-center hover:bg-lightgreenhover text-centerhover shadow  rounded transition duration-300 ease-in-out"
                    >
                      {t('Save Section')}
                    </button>
                  </div>
                </div>
              </>
            );
          }}
        </Formik>
        {addCardItemMutation.isSuccess && <Notification message={t('Card Item Added')} />}
      </div>
    </>
  );
}
