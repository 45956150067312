import { Fragment } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import LanguageList from '../../../SharedComponents/LanguageList';
import Loading from '../../../SharedComponents/Loading';
import { getFormQuestions, getLanguages } from '../ApiForms';
import FormQuestion from '../Components/FormQuestion';

export default function FormQuestions(props) {
  const { languageId, setLanguageId, formTitles } = props;
  const params = useParams();
  const folderId = params.id;
  // Query Data To Fetch Languages
  const languages = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });
  let initialState = {
    formId: folderId,
    paginationFilter: {
      pageNumber: 0,
      pageSize: 0,
      numberOfPages: 0,
      isPaging: false,
      langId: 0,
    },
  };

  const { data, isLoading, isError } = useQuery(['formQuestion', initialState], getFormQuestions);
  if (isLoading) return <Loading />;
  if (isError) return 'error';
  return (
    <>
      <div className="flex justify-between items-center mb-4 relative ">
        <div className="transform -translate-y-2">
          <div className="flex items-center space-x-2 text-gray-600">
            {formTitles.map((form) => {
              return <Fragment>{languageId === form.languageId && <b>{form.title}</b>}</Fragment>;
            })}
          </div>
        </div>
        <div className="flex transform  relative z-10 ">
          {languages && (
            <LanguageList languages={languages.data?.data} languageId={languageId} setLanguageId={setLanguageId} />
          )}
        </div>
        <hr className="border w-full absolute top-0 left-0 z-0 mt-10" />
      </div>
      <div>
        {data.data.map((question) => {
          return <FormQuestion folderId={folderId} languageId={languageId} question={question} />;
        })}
      </div>
    </>
  );
}
