import { Fragment, useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import Modal from '../../SharedComponents/Modal';
import PlusCircle from '../../SharedComponents/Svg/PlusCircle';
import { getFolder, getFolders } from '../Assets/ApiAssets';
import { getLanguages } from './ApiSettings';
import folder from '../../assets/img/folder.svg';
import ChevronLeft from '../../SharedComponents/Svg/ChevronLeft';
import { useTranslation } from 'react-i18next';

export default function AddFavicon(props) {
  const { setField, edit, fieldName } = props;
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="bg-lightgreen space-x-1 text-sm font-bold text-white flex items-center hover:bg-lightgreenhover shadow  px-4 py-2 rounded transition duration-300 ease-in-out"
      >
        <PlusCircle className="w-5 h-5" />
        <span>{edit ? 'Replace Image' : 'Choose Image'}</span>
      </button>

      {isOpen && (
        <ModalContainer
          //   id={id}
          //   typeId={typeId}
          setField={setField}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          fieldName={fieldName}
        />
      )}
    </>
  );
}

const ModalContainer = (props) => {
  const { isOpen, setIsOpen, id, typeId, setField, fieldName } = props;
  const [languageId, setLanguageId] = useState(1);
  const [uploadSucess, setUploadSucess] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [assetCollection, setAssetCollection] = useState([]);
  // Query Data To Fetch Languages
  //   const { data, isLoading, isError } = useQuery('languages', getLanguages, {
  //     staleTime: 360000000,
  //   });
  // entries to control pageSize
  const [entries, setEntries] = useState(10);
  // pageNumber to Control pageNumber
  const [pageNumber, setPageNumber] = useState(1);
  const [publicFolderId, setPublicFolderId] = useState(1);
  // initial state for APi call
  let initialState = {
    typeId: 1,
    paginationFilter: {
      pageNumber: pageNumber,
      pageSize: entries,
      isPaging: false,
      langId: 0,
    },
  };
  // Query to load Data
  const { data, isDone, isLoading, isError } = useQuery(['assetFolders', initialState], getFolders, {
    staleTime: 1000,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    onSuccess: (data) => {
      setPublicFolderId(data?.data[0]?.id !== undefined ? data?.data[0]?.id : 1);
    },
  });
  const { t, i18n } = useTranslation();
  // Query to load publicFolder Data
  const publicFolder = useQuery(['assetFolder', publicFolderId], getFolder, {
    staleTime: 1000,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });

  const onSubmit = async (e) => {
    try {
      console.log(e);
      setField('imageId', assetCollection.id);
      setField(fieldName, assetCollection.url);
      setIsOpen(false);
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onSubmit={onSubmit}
        title="Choose Image"
        // isLoading={mutation.isLoading}
        submitType="button"
        add={true}
        className="sm:max-w-3xl"
      >
        {publicFolderId !== data?.data[0]?.id && (
          <div className="flex justify-end">
            <button
              onClick={() => {
                setPublicFolderId(data?.data[0]?.id);
              }}
              className={`flex focus:outline-none items-center ${
                i18n.language === 'en' ? '' : 'flex-row-reverse  '
              } justify-start border pr-4  bg-white hover:bg-gray-100 rounded text-gray-800 transition duration-500`}
            >
              <ChevronLeft className="w-5 h-5" /> <span>{t('GO BACK')}</span>
            </button>
          </div>
        )}
        <div className="flex items-center grid grid-cols-3 lg:grid-cols-3 gap-8 mt-4 mb-8">
          {data?.data[0]?.id === publicFolderId &&
            data?.data?.slice(1).map((e) => {
              return (
                <FileHolder
                  setPublicFolderId={setPublicFolderId}
                  folderData={e}
                  name={e.name}
                  key={e.id}
                  id={e.id}
                  typeId={activeTab}
                  itemCount={e.itemCount}
                />
              );
            })}
          {publicFolder &&
            publicFolder.data &&
            publicFolder.data.data &&
            publicFolder?.data.data.astAssets
              .filter((e) => e.typeId === activeTab)
              .map((e) => {
                return (
                  <ImageHolder
                    assetCollection={assetCollection}
                    setAssetCollection={setAssetCollection}
                    languageId={languageId}
                    asset={e}
                    key={e.id}
                  />
                );
              })}
        </div>
      </Modal>
    </>
  );
};

const FileHolder = (props) => {
  const { name, itemCount, id, setPublicFolderId, folderData, typeId } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <div className="relative bg-white  z-5 rounded shadow-md border py-4 px-2">
        <div
          onClick={() => {
            setPublicFolderId(id);
          }}
          className=""
        >
          <div className="text-center">
            <img className="inline-block rounded" alt={folder} src={folder} />
            <b className="block mt-1 mb-2">{name}</b>
            <span className="block text-sm text-gray-400">
              {itemCount} {t('Items')}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

const ImageHolder = (props) => {
  const { asset, languageId, assetCollection, setAssetCollection } = props;
  // const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const onChange = () => {
    // console.log('changed', props);
    // if (assetCollection.includes(asset.id) === false)
    //   setAssetCollection([...assetCollection, asset.id]);
    // if (assetCollection.includes(asset.id) === true)
    //   setAssetCollection(assetCollection.filter((e) => e !== asset.id));
    // console.log('asset', asset);

    setAssetCollection({
      id: asset.id,
      url: asset.fileUrl,
    });
  };
  return (
    <>
      <div className="relative bg-white  z-0 rounded shadow-md border p-8" style={{ minHeight: 200 }}>
        <input
          className="absolute top-0 left-0 border m-4 h-4 w-4 "
          type="checkbox"
          checked={assetCollection.id === asset.id}
          onChange={onChange}
        ></input>

        {/* <div
            className={`absolute top-0 right-0 bg-white shadow-md border rounded duration-500 ${
              isOpen ? ' scale-100' : 'transform scale-0'
            } transform  mt-8 mr-4 flex flex-col text-sm text-gray-600 w-24`}
          >
            <button className='text-left flex items-center  space-x-2 hover:bg-gray-100 px-2 py-1'>
              <PecnilAlt className='w-4 h-4 ' />
              <span>Edit </span>
            </button>
            <DeleteFolder id={asset.id} />
          </div> */}

        <div className="w-full">
          <div className="text-center ">
            <div>
              <div className="relative flex justify-center bg-white">
                <img
                  className="inline-block rounded object-cover h-20 w-full"
                  alt={`alt`}
                  style={{ maxWidth: 78, maxHeight: 78 }}
                  src={`${process.env.REACT_APP_IMAGE_PATH}${asset.fileUrl}`}
                />
              </div>
              {asset.astAssetLngs.map((e) => {
                return (
                  <Fragment key={e.id}>
                    {languageId === e.languageId && (
                      <div key={e.id}>
                        <b className="block mt-1 mb-2">{e.title}</b>
                        <span className="block text-sm text-white">{t('am Hidden')}</span>
                      </div>
                    )}
                  </Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
