import { Field, FieldArray, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Modal from '../../../SharedComponents/Modal';
import Cog from '../../../SharedComponents/Svg/Cog';
import { editPermissions, editRole, getPermissions } from './ApiRoles';
import Search from '../../../SharedComponents/Svg/Search';
import Permissions from './Permissions';
import ChevronRight from '../../../SharedComponents/Svg/ChevronRight';
import ChevronLeft from '../../../SharedComponents/Svg/ChevronLeft';
import { useTranslation } from 'react-i18next';

const ModalContainer = (props) => {
  const { id, name, isOpen, setIsOpen } = props;

  const [searchPermissions, setSearchPermissions] = useState('');
  const [currentPermissions, setCurrentPermissions] = useState([]);
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const { data, refetch } = useQuery('Permissions', getPermissions(name), {
    staleTime: 7000,

    onSuccess: async (data) => {
      setCurrentPermissions(data.data);
    },
  });

  const newCurrentPermissions = [];
  const newAllPermissions = [];
  // useEffect(() => {}, [name, isOpen]);
  const [allPermissions, setAllPermissions] = useState(Permissions);

  // onSearchPermissions
  const handleAllPermissions = (e) => {
    // TODO
    // check if target is checked
    // make sure it's not duplicated
    // add it to fake array
    if (e.target.checked) {
      // !currentPermissions.includes(e)
      if (!newAllPermissions.includes(e.target.dataset.id)) newAllPermissions.push(e.target.dataset.id);
    }
  };
  const handleCurrentPermissions = (e) => {
    // TODO
    // check if target is checked
    // make sure it's not duplicated
    // add it to fake array
    if (e.target.checked) {
      // !currentPermissions.includes(e)
      if (!newCurrentPermissions.includes(e.target.dataset.id)) newCurrentPermissions.push(e.target.dataset.id);
    }
  };

  const handleLeftToRight = () => {
    setCurrentPermissions([...currentPermissions, ...newAllPermissions]);
  };
  const handleRightToLeft = () => {
    // const m = currentPermissions.map((e) => e !== );
    const toRemoveMap = newCurrentPermissions.reduce(
      (memo, item) => ({
        ...memo,
        [item]: true,
      }),
      {}
    );

    const filteredArray = currentPermissions.filter((x) => !toRemoveMap[x]);
    setCurrentPermissions(filteredArray);
  };
  const onSearchPermissions = (e) => {
    setSearchPermissions(e.target.value);
  };

  const { mutate } = useMutation('editPermissions', editPermissions, {
    onSuccess: () => {
      queryClient.invalidateQueries(['Permissions']);
      console.log('done');
      setIsOpen(false);
    },
  });
  const onSubmit = async (e) => {
    const newData = {
      roleName: name,
      claims: currentPermissions,
    };
    try {
      await mutate(newData);
      if (data?.isDone) {
        setIsOpen(false);
      }
    } catch (error) {
      console.log('error', error);
    }
  };
  return (
    <Formik
      initialValues={{
        currentPermissions,
        allPermissions,
      }}
      onSubmit={onSubmit}
    >
      {({ values }) => {
        return (
          <Form>
            <Modal
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              title={`${t('Edit Role')} ${name} ${'Permissions'}`}
              add={true}
              onSubmit={onSubmit}
              className="sm:max-w-3xl"
            >
              <div className="flex justify-between">
                <div className="border rounded w-80">
                  <div className="px-5 py-4 border-b">
                    <label
                      className={`text-sm font-normal text-gray-500 flex items-center space-x-2 ${
                        i18n.language === 'en' ? '' : 'space-x-reverse'
                      }`}
                    >
                      <input className="border-gray-500 text-gray-500 rounded-none" key={222} type="checkbox" />
                      <span>
                        {allPermissions.length} {t('items')}
                      </span>
                    </label>
                  </div>
                  <div className="flex items-center border my-4 mx-4 p-1 px-2 rounded">
                    <input
                      className="w-full focus:outline-none"
                      type="text"
                      placeholder={t('Search here')}
                      onChange={onSearchPermissions}
                    />
                    <Search className="w-4 h-4" />
                  </div>

                  <div className=" flex flex-col overflow-y-scroll max-h-56 space-y-2 pl-5">
                    {allPermissions &&
                      allPermissions
                        .filter((e) => !currentPermissions.includes(e))
                        .filter((e) => e.toLowerCase().includes(searchPermissions.toLowerCase()))
                        .map((allPermission) => {
                          return (
                            <FieldArray
                              key={allPermission}
                              name="pgPageLngs"
                              render={(arrayHelpers) => (
                                <label
                                  key={allPermission}
                                  className={`text-sm font-normal text-gray-500 flex items-center space-x-2 ${
                                    i18n.language === 'en' ? '' : 'space-x-reverse'
                                  }`}
                                >
                                  <input
                                    className="border-gray-500 text-gray-500 rounded-none"
                                    key={allPermission}
                                    onChange={handleAllPermissions}
                                    data-id={allPermission}
                                    type="checkbox"
                                  />
                                  <span>{allPermission}</span>
                                </label>
                              )}
                            />
                          );
                        })}
                  </div>
                  <div className=" border-t text-center text-xs text-gray-400 font-normal py-2">
                    {t('All Permissions')}
                  </div>
                </div>

                <div className="flex items-center">
                  <div className="flex flex-col space-y-3">
                    <button
                      type="button"
                      className="bg-gray-100 hover:bg-gray-200 text-gray-300 rounded p-1"
                      onClick={handleLeftToRight}
                    >
                      <ChevronRight className="w-5 h-5" />
                    </button>
                    <button
                      type="button"
                      className="bg-gray-100 hover:bg-gray-200 text-gray-300 rounded p-1"
                      onClick={handleRightToLeft}
                    >
                      <ChevronLeft className="w-5 h-5" />
                    </button>
                  </div>
                </div>

                <div className="border rounded w-80">
                  <div className="px-5 py-4 border-b">
                    <label
                      className={`text-sm font-normal text-gray-500 flex items-center space-x-2 ${
                        i18n.language === 'en' ? '' : 'space-x-reverse'
                      }`}
                    >
                      <input className="border-gray-500 text-gray-500 rounded-none" key={222} type="checkbox" />
                      <span>
                        {currentPermissions.length} {t('items')}
                      </span>
                    </label>
                  </div>
                  <div className="flex items-center border my-4 mx-4 p-1 px-2 rounded">
                    <input
                      className="w-full focus:outline-none"
                      type="text"
                      placeholder={t('Search here')}
                      onChange={onSearchPermissions}
                    />
                    <Search className="w-4 h-4" />
                  </div>

                  <div className=" flex flex-col overflow-y-scroll max-h-56 space-y-2 pl-5">
                    {currentPermissions
                      .filter((e) => e.toLowerCase().includes(searchPermissions.toLowerCase()))
                      .map((currentPermission) => {
                        return (
                          <label
                            className={`text-sm font-normal text-gray-500 flex items-center space-x-2 ${
                              i18n.language === 'en' ? '' : 'space-x-reverse'
                            }`}
                          >
                            <input
                              className="border-gray-500 text-gray-500 rounded-none"
                              value={currentPermission}
                              onChange={handleCurrentPermissions}
                              data-id={currentPermission}
                              type="checkbox"
                            />
                            <span>{t(currentPermission)}</span>
                          </label>
                        );
                      })}
                  </div>
                  <div className=" border-t text-center text-xs text-gray-400 font-normal py-2">
                    {t('Current Permissions')}
                  </div>
                </div>
              </div>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};

export default function RolePermission(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { id, name } = props;

  return (
    <>
      <button
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className="bg-yellow-100 text-yellow-800 hover:bg-yellow-200  p-1 rounded transition duration-300 ease-in-out"
      >
        <Cog className="w-5 h-5" />
      </button>
      {isOpen && <ModalContainer id={id} isOpen={isOpen} setIsOpen={setIsOpen} name={name} />}
    </>
  );
}
