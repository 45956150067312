import { Link } from 'react-router-dom';
import DotsVertical from '../../../SharedComponents/Svg/DotsVertical';
import PecnilAlt from '../../../SharedComponents/Svg/PencilAlt';
import DeleteFolder from '../DeleteFolder';
import folder from '../../../assets/img/folder.svg';
import { Fragment } from 'react';
import EditFolder from './EditFolder';
import { useState } from 'react';
import EditUploadAsset from '../EditUploadAsset';
import DeleteFile from './DeleteFile';
import DeleteAsset from './DeleteAsset';
import EditAsset from './EditAsset';
import { useMutation, useQueryClient } from 'react-query';
import { deleteAsset } from '../ApiAssets';
import Notification from '../../../SharedComponents/Notification';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from '../../../SharedComponents/OutsideClickHandler';

const FileHolder = (props) => {
  const { name, itemCount, id, folderData, typeId } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <div className="relative bg-white  z-5 rounded shadow-md border p-8">
        <OutsideClickHandler setIsOpen={setIsOpen}>
          <button className="absolute top-0 z-10 right-0 mt-2 mr-2 text-gray-700" onClick={() => setIsOpen(!isOpen)}>
            <DotsVertical className="w-5 h-5" />
          </button>
          <div
            className={`absolute top-0 right-0 bg-white shadow-md border rounded duration-500 ${
              isOpen ? '' : 'hidden'
            }   mt-8 mr-4 flex flex-col text-sm text-gray-600 w-24`}
          >
            <EditFolder typeId={typeId} folder={folderData} withText={true} />
            <DeleteFolder id={id} />
          </div>
        </OutsideClickHandler>
        <Link to={`/assets/1/${id}`} className="">
          <div className="text-center">
            <img className="inline-block rounded" alt={folder} src={folder} />
            <b className="block mt-1 mb-2">{name}</b>
            <span className="block text-sm text-gray-400">
              {itemCount} {t('Items')}
            </span>
          </div>
        </Link>
      </div>
    </>
  );
};

const ImageHolder = (props) => {
  const { asset, languageId, assetCollection, setAssetCollection } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const deleteAssetMutation = useMutation(deleteAsset, {
    onSuccess: () => {
      queryClient.invalidateQueries(['assetFolder']);
      // setIsOpen(false);
    },
  });
  const onChange = () => {
    console.log('changed', props);
    if (assetCollection.includes(asset.id) === false) setAssetCollection([...assetCollection, asset.id]);
    if (assetCollection.includes(asset.id) === true) setAssetCollection(assetCollection.filter((e) => e !== asset.id));
  };
  return (
    <>
      {deleteAssetMutation.isSuccess && <Notification message={t('Asset Deleted')} />}
      {deleteAssetMutation.isError && (
        <Notification error message={t('Cannot Delete, This Asset is used somewhere else')} />
      )}
      <div className="relative bg-white  z-5 rounded shadow-md border p-8" style={{ minHeight: 200 }}>
        <input
          className="absolute top-0 left-0 border m-4 h-4 w-4 "
          type="checkbox"
          checked={assetCollection.includes(asset.id)}
          onChange={onChange}
        ></input>
        <OutsideClickHandler setIsOpen={setIsOpen}>
          <button className="absolute top-0 z-10 right-0 mt-2 mr-2 text-gray-700" onClick={() => setIsOpen(!isOpen)}>
            <DotsVertical className="w-5 h-5" />
          </button>
          <div
            className={`absolute top-0 right-0 bg-white shadow-md border rounded duration-500 ${
              isOpen ? '' : 'hidden'
            }   mt-8 mr-4 flex flex-col text-sm text-gray-600 w-24 `}
          >
            <EditAsset withText asset={asset} id={asset.id} />
            <DeleteAsset deleteAssetMutation={deleteAssetMutation} withText id={asset.id} />
          </div>
        </OutsideClickHandler>

        <button className="w-full">
          <div className="text-center ">
            <div>
              <div className=" flex justify-center bg-white">
                {/* <img
                  className='inline-block rounded'
                  alt='alt'
                  src={`${process.env.REACT_APP_IMAGE_PATH}${asset.fileUrl}`}
                /> */}

                <img
                  className="inline-block rounded h-20 w-full"
                  alt={`alt`}
                  style={{ maxWidth: 78, maxHeight: 78 }}
                  src={`${process.env.REACT_APP_IMAGE_PATH}${asset.fileUrl}`}
                />
              </div>
              {asset.astAssetLngs.map((e) => {
                return (
                  <Fragment key={e.id}>
                    {languageId === e.languageId && (
                      <div key={e.id}>
                        <b className="block mt-1 mb-2">{e.title}</b>
                        <span className="block text-sm text-white">{t('am Hidden')}</span>
                      </div>
                    )}
                  </Fragment>
                );
              })}
            </div>
          </div>
        </button>
      </div>
    </>
  );
};

export default function ImageTypeContainer(props) {
  // Container for Image Type Style
  const { data, publicFolder, languageId, activeTab, assetCollection, setAssetCollection } = props;
  return (
    <>
      <div className="flex items-center grid grid-cols-3 lg:grid-cols-6 gap-8 my-8">
        {data.data.slice(1).map((e) => {
          return (
            <FileHolder folderData={e} name={e.name} key={e.id} id={e.id} typeId={activeTab} itemCount={e.itemCount} />
          );
        })}
        {publicFolder &&
          publicFolder.data &&
          publicFolder.data.data &&
          publicFolder?.data.data.astAssets
            .filter((e) => e.typeId === activeTab)
            .map((e) => {
              return (
                <ImageHolder
                  assetCollection={assetCollection}
                  setAssetCollection={setAssetCollection}
                  languageId={languageId}
                  asset={e}
                  key={e.id}
                />
              );
            })}
      </div>
    </>
  );
}
