import { FastField, Field, FieldArray, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { addPage, getLanguages } from './ApiPage';
import PencilAlt from '../../../SharedComponents/Svg/PencilAlt';
import Flag from '../../../SharedComponents/Svg/Flag';
import RichTextEditor from './RichTextEditor';
import { DateTime } from 'luxon';
import CustomFormField from './CustomFormField';
import AddThumbnail from './AddThumbnail';
import PagesList from './PagesList';
import { useTranslation } from 'react-i18next';
import LanguageList from '../../../SharedComponents/LanguageList';
import { getCategories } from '../../Categories/ApiCategories';

export default function CreatePage() {
  // initial states
  const [languageId, setLanguageId] = useState(1);
  const [categoryId, setCategoryId] = useState(0);
  let categorystate = {
    pageNumber: 0,
    pageSize: 0,
    isPaging: false,
    langId: 0,
    isSearch: false,
  };
  const CategoriesQuery = useQuery(['categories', categorystate], getCategories);
  const categories = [
    { id: 0, name: 'Blogs' },
    { id: 1, name: 'News' },
    { id: 2, name: 'Events' },
    { id: 3, name: 'Projects' },
    { id: 7, name: 'اتصل بنا' },
    { id: 8, name: 'من نحن' },
    { id: 11, name: 'أسئلة شائعة' },
    { id: 12, name: 'سياسة الاستخدام' },
    { id: 13, name: 'خريطة الموقع' },
    { id: 14, name: 'المدن الرئيسية' },
  ];
  const { t, i18n } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  // useEffect to not let the Component Reload
  useEffect(() => {}, []);
  // Query Data To Fetch Languages
  const { data } = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });
  // Mutate Data to Create New Page
  const mutation = useMutation(addPage, {
    onSuccess: async (data) => {
      if (data.isDone) {
        window.location.href = `/pages/${data.outId}`;
      }
    },
  });
  // Handling onSubmit Form
  const onSubmit = async (e) => {
    console.log(e.pgPageCategoryId);
    const newData = {
      ...e,
      uniqueUrl: e.uniqueUrl === null ? e.pgPageLngs[0].title.replace(/\s+/g, '_').toLowerCase() : e.uniqueUrl,
      pageDate: DateTime.fromISO(e.pageDate).toISO(),
      pageDateString: e.pageDate,
      pgEvent:
        e.pgPageCategoryId === 2
          ? {
              ...e.pgEvent,
              startDate: DateTime.fromISO(e.pgEvent.startDate).toISO(),
              endDate: DateTime.fromISO(e.pgEvent.endDate).toISO(),
              startDatestring: e.pgEvent?.startDate,
              endDatestring: e.pgEvent?.endDate,
            }
          : null,
    };
    console.log(newData);
    try {
      await mutation.mutate(newData);
    } catch (error) {}
  };

  //  Handle Changing Main Language
  const onCategoryChange = (e) => {
    setCategoryId(parseInt(e.target.value));

    if (e.target.value !== 2) {
      setActiveTab(0);
    }
  };

  // Handle Multiple Language Content in the Page
  const pgPageLngs = data?.data?.map((language) => ({
    languageId: language.id,
    title: '',
    content: '',
    description: '',
    seoDesc: '',
    seoTitle: '',
    keywords: '',
  }));

  // Handle Multiple Language Content in Events
  const pgEventLngs = data?.data?.map((language) => ({
    languageId: language.id,
    place: '',
    organizer: '',
  }));
  //  Initial Values for Page Data
  const initialValues = {
    uniqueUrl: null,
    pageDate: DateTime.now().toISODate(),
    pageViews: 0,
    imageId: 0,
    typeId: 0,
    statusId: '0',
    showOn: 'string',
    pgPageCategoryId: 4,
    layout: 'string',
    albumId: 0,
    parentId: 0,
    allowComments: true,
    commentNeedApprove: true,
    ratingAvg: null,
    ratingCount: null,
    ratingLastDate: null,
    pgPageLngs: pgPageLngs,
    pgEvent: {
      id: 0,
      startDate: DateTime.now().toISODate(),
      endDate: DateTime.now().toISODate(),
      location:
        'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13800.213865263484!2d31.373177237914142!3d30.149887364329963!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x145816ccdb899131%3A0x3ccf8e32cabbdd20!2sKafr%20Abu%20Sir%2C%20Berket%20an%20Nasr%2C%20Al%20Salam%20First%2C%20Cairo%20Governorate!5e0!3m2!1sen!2seg!4v1614073767221!5m2!1sen!2seg',
      pgPageId: 0,
      pgEventLngs: pgEventLngs,
    },
  };

  return (
    <div>
      {data && data.data && (
        <Formik
          onSubmit={onSubmit}
          onChange={() => {
            console.log('changes');
          }}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialValues}
        >
          {({ values, setFieldValue, onChange }) => {
            return (
              <Form>
                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-2 bg-white shadow border rounded p-4 text-gray-600 font-bold">
                    <div className="flex justify-between relative items-center">
                      <div className="flex transform -translate-y-1 relative z-10">
                        <div
                          key={0}
                          className={`flex justify-center items-center space-x-2 ${
                            i18n.language === 'en' ? '' : 'space-x-reverse'
                          } ${
                            activeTab === 0 ? 'text-pagetabhover border-pagetabhover' : ''
                          }   hover:text-pagetabhover pb-4 px-4 border-b-2 hover:border-pagetabhover transition duration-300 ease-in-out cursor-pointer `}
                          onClick={(e) => setActiveTab(0)}
                        >
                          <PencilAlt />
                          <span>{t('Content')}</span>
                        </div>

                        <div
                          key={1}
                          className={`flex justify-center items-center space-x-2 ${
                            i18n.language === 'en' ? '' : 'space-x-reverse'
                          } ${
                            activeTab === 1 ? 'text-pagetabhover border-pagetabhover' : ''
                          }   hover:text-pagetabhover pb-4 px-4 border-b-2 hover:border-pagetabhover transition duration-300 ease-in-out cursor-pointer `}
                          onClick={(e) => setActiveTab(1)}
                        >
                          <Flag />
                          <span>{t('Seo')}</span>
                        </div>
                        <div
                          key={2}
                          className={`flex justify-center items-center space-x-2 ${categoryId === 2 ? '' : 'hidden'} ${
                            activeTab === 2 ? 'text-pagetabhover border-pagetabhover' : ''
                          }   hover:text-pagetabhover pb-4 px-4 border-b-2 hover:border-pagetabhover transition duration-300 ease-in-out cursor-pointer `}
                          onClick={(e) => setActiveTab(2)}
                        >
                          <Flag />
                          <span>{t('Location')}</span>
                        </div>
                      </div>
                      <div>
                        <div>
                          {data && data?.data && (
                            <LanguageList
                              className="flex justify-end transform translate-y-1 relative z-10 mb-4             "
                              languages={data?.data}
                              languageId={languageId}
                              setLanguageId={setLanguageId}
                            />
                          )}
                        </div>
                      </div>
                      <hr className="border w-full absolute top-0 left-0 z-0 mt-11" />
                    </div>
                    <>
                      {values &&
                        values?.pgPageLngs.map((page, index) => (
                          // activeTab

                          <FieldArray
                            key={index}
                            name="pgPageLngs"
                            render={(arrayHelpers) => (
                              <>
                                {activeTab === 0 && languageId === page.languageId && (
                                  <>
                                    {/* {console.log(
                                        languageId === page.languageId
                                      )} */}
                                    <div className="py-8">
                                      {/* <Field
                                          className='hidden'
                                          name='languageId'
                                        /> */}
                                      <FastField
                                        style={{ textIndent: '10px' }}
                                        className="border rounded  w-full mb-8 p-1 hidden"
                                        name={`pgPageLngs[${index}].id`}
                                        type="number"
                                        // value={page.title}
                                      />
                                      <CustomFormField
                                        label={t('Page Title')}
                                        name={`pgPageLngs[${index}].title`}
                                        fieldValue={values.pgPageLngs[index].title}
                                        setFieldValue={setFieldValue}
                                      />
                                      <CustomFormField
                                        label={t('Description')}
                                        name={`pgPageLngs[${index}].description`}
                                        fieldValue={values.pgPageLngs[index].description}
                                        setFieldValue={setFieldValue}
                                        as="textarea"
                                      />

                                      <RichTextEditor
                                        fieldName={`pgPageLngs[${index}].content`}
                                        fieldValue={values.pgPageLngs[index].content}
                                        setFieldValue={setFieldValue}
                                      />
                                    </div>
                                  </>
                                )}
                                {activeTab === 1 && languageId === page.languageId && (
                                  <>
                                    <div className="py-8">
                                      <label>
                                        {t('SEO Title')}
                                        <Field
                                          style={{ textIndent: '10px' }}
                                          className="border rounded  w-full mb-8 p-1"
                                          name={`pgPageLngs[${index}].seoTitle`}
                                          type="text"
                                        />
                                      </label>
                                      <label>
                                        {t('SEO Description')}
                                        <Field
                                          style={{ textIndent: '10px' }}
                                          className="border rounded  w-full mb-8 p-1"
                                          name={`pgPageLngs[${index}].seoDesc`}
                                          type="text"
                                        />
                                      </label>
                                      <label>
                                        {t('SEO Keywords')}
                                        <Field
                                          style={{ textIndent: '10px' }}
                                          className="border rounded  w-full mb-8 p-1"
                                          name={`pgPageLngs[${index}].keywords`}
                                          // type='text'
                                          as="textarea"
                                        />
                                      </label>
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          />
                        ))}
                    </>
                    {activeTab === 2 && (
                      <div className="py-8">
                        {values &&
                          values?.pgEvent.pgEventLngs.map((event, index) => (
                            <FieldArray
                              key={index}
                              name="pgEvent.pgEventLngs"
                              render={(arrayHelpers) => (
                                <>
                                  {languageId === event.languageId && (
                                    <>
                                      <label>
                                        {t('Place')}
                                        <Field
                                          className="border rounded  w-full mb-8 p-1"
                                          type="text"
                                          name={`pgEvent.pgEventLngs[${index}].place`}
                                        />
                                      </label>
                                      <label>
                                        {t('Organizer')}
                                        <Field
                                          className="border rounded  w-full mb-8 p-1"
                                          type="text"
                                          name={`pgEvent.pgEventLngs[${index}].organizer`}
                                        />
                                      </label>
                                    </>
                                  )}
                                </>
                              )}
                            />
                          ))}
                        <label>
                          {t('Location Google Maps Url')}
                          <Field
                            className="border rounded  w-full mb-8 p-1"
                            type="url"
                            name="pgEvent.location"
                            onChange={(e) => {
                              setFieldValue('pgEvent.location', e.target.value);
                            }}
                          />
                        </label>
                        <iframe
                          title="map"
                          src={values.pgEvent.location}
                          width="100%"
                          height="450"
                          allowfullscreen=""
                          loading="lazy"
                        ></iframe>
                      </div>
                    )}
                    <div className="text-right">
                      <button type="submit" className="bg-darkblue hover:bg-darkbluehover text-white px-4 py-2 rounded">
                        {t('Save')}
                      </button>
                    </div>
                  </div>
                  <div className="space-y-8">
                    {/* Page Options */}
                    <div className="bg-white p-4 rounded shadow">
                      <div
                        className={`border-b-2 text-gray-500 flex space-x-2 ${
                          i18n.language === 'en' ? '' : 'space-x-reverse'
                        }  py-2`}
                      >
                        <svg
                          className="w-6 h-6"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                          ></path>
                        </svg>
                        <span className="font-bold">{t('Page Options')}</span>
                      </div>
                      <div className="my-4 space-y-8 relative text-gray-600">
                        <Field className={`border rounded hidden w-full p-1`} name={`imageId`} type="number" />
                        <div className="h-64 focus:outline-none group rounded border-dashed border-2 mb-4 relative">
                          {(values.imageUrl && (
                            <>
                              <img
                                className="object-fit h-64 w-full"
                                alt="img"
                                src={`${process.env.REACT_APP_IMAGE_PATH}${values.imageUrl}`}
                              />
                              <div className="absolute group-hover:opacity-100 opacity-0 inset-0 flex items-center justify-center">
                                <AddThumbnail edit={true} setField={setFieldValue} />
                              </div>
                            </>
                          )) || (
                            <div className="absolute inset-0 flex items-center justify-center">
                              <AddThumbnail setField={setFieldValue} />
                            </div>
                          )}
                        </div>
                        <CustomFormField
                          name="uniqueUrl"
                          setFieldValue={setFieldValue}
                          label={t('uniqueUrl')}
                          className="border rounded  w-full mb-0 p-1"
                          fieldValue={values.pgPageLngs[0].title.replace(/\s+/g, '_').toLowerCase()}
                        />

                        <div>
                          <PagesList setFieldValue={setFieldValue} parentId={values.parentId} languageId={languageId} />
                        </div>
                        {/* <label className='flex items-center space-x-2'>
                          <Field
                            type='checkbox'
                            name='toggle'
                            className='w-4 h-4'
                          />
                          <span>Show in home</span>
                        </label> */}
                        <div className="grid grid-cols-3">
                          <label
                            className={`flex items-center space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}
                          >
                            <Field type="radio" name="statusId" value="1" className="w-4 h-4" />
                            <span>{t('Published')}</span>
                          </label>
                          <label
                            className={`flex items-center space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}
                          >
                            <Field type="radio" name="statusId" value="0" className="w-4 h-4" />
                            <span>{t('Draft')}</span>
                          </label>
                        </div>

                        <div>
                          <Field
                            className="border rounded py-1 px-2 w-full"
                            as="select"
                            name="pgPageCategoryId"
                            onChange={(e) => {
                              setFieldValue('pgPageCategoryId', parseInt(e.target.value));
                              onCategoryChange(e);
                            }}
                          >
                            {CategoriesQuery.data?.data.map((category) => {
                              let categoryname = category.pgPageCategoryLngs.filter((e) => {
                                if (e.languageId === languageId) {
                                  return e.name;
                                }
                                return '';
                              });
                              return (
                                <option value={parseInt(category.id)} key={category.id}>
                                  {console.log(categoryname)}
                                  {categoryname[0]?.name}
                                  {/* {t(category.name)} */}
                                </option>
                              );
                            })}
                          </Field>
                          <label className={`my-4 block ${categoryId === 0 ? '' : 'hidden'} `}>
                            {t('Date')}
                            <Field className="w-full border p-2 rounded " type="date" name="pageDate" />
                          </label>
                          {categoryId === 2 && (
                            <>
                              <div className="flex justify-between py-4 space-x-8">
                                <label>
                                  {t('From')}
                                  <Field className="w-full border p-2 rounded" type="date" name="pgEvent.startDate" />
                                </label>
                                <label>
                                  {t('To')}
                                  <Field
                                    className="w-full border p-2 rounded"
                                    type="date"
                                    name="pgEvent.endDate"
                                    min={values?.pgEvent?.startDate}
                                    disabled={!values?.pgEvent}
                                  />
                                </label>
                              </div>
                              <div
                                className={`flex justify-between py-4 ${
                                  i18n.language === 'en' ? '' : 'space-x-reverse'
                                } space-x-1`}
                              >
                                <label className="w-1/2">
                                  {t('From')} Time
                                  <Field
                                    className="w-full border  p-0 py-1 2xl:p-3 rounded"
                                    type="time"
                                    name="pgEvent.timeFrom"
                                    // min={new Date().toString()}
                                  />
                                </label>
                                <label className="w-1/2">
                                  {t('To')} Time
                                  <Field
                                    className="w-full border   p-0 py-1  2xl:p-3  rounded"
                                    type="time"
                                    name="pgEvent.timeTo"
                                    min={values?.pgEvent?.timeTo}
                                    // disabled={!values?.pgEvent}
                                  />
                                </label>
                              </div>
                            </>
                          )}
                        </div>
                        <div>
                          <label
                            className={`flex items-center space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}
                          >
                            <Field type="checkbox" name="showOnSidebar" className="w-4 h-4" />
                            <span>{t('Show Sidebar')}</span>
                          </label>
                        </div>
                      </div>
                      <div className={`justify-end flex space-x-4 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
                        <button className="bg-pink hover:bg-pinkhover text-white px-4 py-2 rounded">
                          {t('Preview')}
                        </button>
                        <button
                          type="submit"
                          className="bg-darkblue hover:bg-darkbluehover text-white px-4 py-2 rounded"
                        >
                          {t('Save')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
}
